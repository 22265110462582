<template>
  <div class="imt-quote">
    <b-card
      no-body
      class="banner"
      ref="banner"
      :class="bannerWidth < 769 ? 'banner-vert' : ''"
    >
      <template v-if="!missingRequired">
        <b-card-body v-if="contract">
          <div class="row align-items-center m-0">
            <div
              class="pt-3 pb-3 banner-left"
              :class="bannerWidth < 769 ? 'col-12' : 'col-md-4'"
            >
              <img class="logo" :src="clientManagerLogo" />
            </div>
            <div
              class="pt-3 pb-3 banner-middle"
              :class="bannerWidth < 769 ? 'col-12' : 'col-md-4'"
            >
              <h4 class="premium" v-if="!loadingQuote">
                <span
                  class="text-light"
                  v-if="premiumToPay && premiumToPay.localAmount"
                >
                  {{ $t("content.add_quote_text") }}
                  <strong>
                    {{
                      premiumToPay.localAmount
                        | formatMoney(premiumToPay.localCurrency)
                    }}
                  </strong>
                </span>
                <span class="text-light" v-else-if="premiumToPay">
                  {{ $t("content.add_quote_text") }}
                  <strong>
                    {{
                      premiumToPay.amount | formatMoney(premiumToPay.currency)
                    }}
                  </strong>
                </span>
                <span class="text-light" v-else-if="state.isValidationError">
                  {{ $t("content.add_insurance_title") }}
                </span>
                <span class="text-light" v-else>{{
                  $t("content.no_offer")
                }}</span>
              </h4>
              <div v-else class="spinner-border text-light" role="status">
                <span class="sr-only">{{ $t("content.loading") }}...</span>
              </div>
            </div>
            <div
              class="pt-3 pb-3 banner-right"
              :class="bannerWidth < 769 ? 'col-12' : 'col-md-4'"
            >
              <b-button
                :disabled="!premiumToPay && !state.isValidationError"
                @click="modal = true"
                variant="orange-small"
                class="sheen"
              >
                <b-icon icon="plus" v-if="premiumToPay"></b-icon>
                <span v-if="premiumToPay">{{
                  $t("content.add_insurance_btn")
                }}</span>
                <span v-else-if="state.isValidationError">{{
                  $t("content.get_quote")
                }}</span>
                <span v-else>{{ $t("content.no_offer") }}</span>
              </b-button>
            </div>
          </div>
        </b-card-body>
        <b-card-footer v-if="contract">
          <div class="logos-container">
            <div class="row">
              <template
                v-for="(item, index) in contract.ContractData.Data
                  .ContractSettings.LogoEntities"
              >
                <div
                  class="col-auto pt-1 footer-logo"
                  :key="'logo-' + index"
                  v-if="
                    state.entityLogos[item] && state.entityLogos[item].HasLogo
                  "
                >
                  <img :src="state.entityLogos[item].Url" />
                </div>
              </template>
              <div class="col-auto pt-1 footer-logo">
                <img
                  src="https://insuremytrans.tech/images/Logo/powered-by.png"
                />
              </div>
            </div>
          </div>
        </b-card-footer>
      </template>
      <div v-else>
        <h4 class="text-white p-4">Missing required fields for component.</h4>
      </div>
    </b-card>
    <div v-if="modal" class="custom-modal fixed-height">
      <div class="custom-modal-content">
        <div class="custom-modal-header">
          <div class="header-logo">
            <img :src="clientManagerLogo" />
          </div>
          <div class="header-text"></div>
          <div @click="modal = false" class="close white-close clickable">
            <i class="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>
        <div class="custom-modal-body fixed-height">
          <form-wizard
            title
            subtitle
            shape="tab"
            id="form-wizard"
            ref="form"
            @on-complete="onComplete"
            color="#fff"
            error-color="#fff"
          >
            <custom-wizard-step
              slot-scope="props"
              slot="step"
              :tab="props.tab"
              :transition="props.transition"
              :index="props.index"
            ></custom-wizard-step>
            <tab-content
              :title="$t('content.missing_information')"
              :before-change="validateMissing"
              v-if="!hideMissingInformation"
            >
              <div class="form-tab-container">
                <missing-information
                  @is-empty="hideMissing"
                  ref="missingInformation"
                  :key="inputsUpdated"
                  :inputs="inputs"
                  :inputsToEdit="inputsToEdit"
                  :state="state"
                  :contract="contract"
                  :countryStates="countryStates"
                />
              </div>
            </tab-content>
            <tab-content
              :title="$t('content.customer_details')"
              :before-change="validateCustomer"
              v-if="!hideCustomerDetails"
            >
              <div class="form-tab-container">
                <customer-details
                  @is-empty="hideCustomer"
                  ref="customerDetails"
                  :key="customerDetailsUpdated"
                  :customerDetails="customerDetails"
                  :customerDetailsToEdit="customerDetailsToEdit"
                  :contract="contract"
                  :inputsToEdit="inputsToEdit"
                  :countryStates="countryStates"
                />
              </div>
            </tab-content>
            <tab-content
              :title="$t('content.additional_information')"
              :before-change="validateAdditional"
              v-if="!hideAdditionalInformation"
            >
              <div class="form-tab-container">
                <additional-information
                  @is-empty="hideAdditional"
                  ref="additionalInformation"
                  :key="additionalUpdated"
                  :additionalQuestions="additionalQuestions"
                  :additionalsToEdit="additionalsToEdit"
                  :inputsToEdit="inputsToEdit"
                  :contract="contract"
                  :countryStates="countryStates"
                />
              </div>
            </tab-content>
            <tab-content :title="$t('content.price_plan')">
              <div class="form-tab-container">
                <quote-configuration
                  @update-quote="getQuote"
                  :key="inputsUpdated"
                  :contract="contract"
                  :inputsToEdit="inputsToEdit"
                  :state="state"
                  :result="result"
                  :countryStates="countryStates"
                  :hideAdminCurrency="hideAdminCurrency"
                />
              </div>
            </tab-content>
            <tab-content
              :title="$t('content.deductible')"
              v-if="contract.HasSelfrisk"
            >
              <div class="form-tab-container">
                <deductible
                  :key="inputsUpdated"
                  :inputsToEdit="inputsToEdit"
                  :state="state"
                  :result="result"
                />
              </div>
            </tab-content>
            <tab-content
              :before-change="validateConfirmation"
              :title="$t('content.confirmation')"
            >
              <div class="form-tab-container">
                <confirmation
                  :key="inputsUpdated"
                  ref="confirmation"
                  :contract="contract"
                  :inputsToEdit="inputsToEdit"
                  :purchasedByThirdParty="purchasedByThirdParty"
                  :state="state"
                  :hasRestrictions="hasRestrictions"
                  :hasGeneralTerms="hasGeneralTerms"
                  :result="result"
                  :countryStates="countryStates"
                  :premiumToPay="premiumToPay"
                  :hideAdminCurrency="hideAdminCurrency"
                  @setCustomerConnection="setCustomerConnection"
                />
              </div>
            </tab-content>
            <b-button
              @click.stop="props.nextTab()"
              variant="orange"
              name="Next"
              slot="next"
              slot-scope="props"
              >{{ $t("content.next") }}</b-button
            >
            <b-button variant="white" name="Back" slot="prev">{{
              $t("content.back")
            }}</b-button>
            <b-button
              :disabled="!readyForOrder || orderSucceeded"
              variant="mint"
              name="Finish"
              slot="finish"
            >
              <template v-if="orderSucceeded">
                <span>Order succeeded</span>
              </template>
              <template v-else-if="!addInsuranceIsLoading">
                <b-icon icon="plus"></b-icon>
                <span>{{ $t("content.add_insurance_confirm_btn") }}</span>
              </template>
              <div
                v-else
                class="spinner-border spinner-border-small text-light"
                role="status"
              >
                <span class="sr-only">{{ $t("content.loading") }}...</span>
              </div>
            </b-button>
          </form-wizard>
        </div>
        <div class="custom-modal-premium">
          <h4 class="premium-text-bottom">
            <template v-if="loadingQuote">
              <div class="spinner-border text-light" role="status">
                <span class="sr-only">{{ $t("content.loading") }}...</span>
              </div>
            </template>
            <template v-else-if="premiumToPay">
              {{ $t("content.premium_to_pay") }}
              <strong v-if="premiumToPay && premiumToPay.localAmount">
                {{
                  premiumToPay.localAmount
                    | formatMoney(premiumToPay.localCurrency)
                }}
              </strong>
              <strong v-else-if="premiumToPay">
                {{ premiumToPay.amount | formatMoney(premiumToPay.currency) }}
              </strong>
            </template>
            <template v-else>
              <span class="text-light mr-2">
                {{
                  state.isValidationError
                    ? $t("content.no_quote")
                    : $t("content.no_offer")
                }}
              </span>
            </template>
          </h4>
        </div>
        <div class="custom-modal-footer">
          <div class="logos-container">
            <div class="row">
              <template
                v-for="(item, index) in contract.ContractData.Data
                  .ContractSettings.LogoEntities"
              >
                <div
                  class="col-auto pt-1 footer-logo"
                  :key="'logo-' + index"
                  v-if="
                    state.entityLogos[item] && state.entityLogos[item].HasLogo
                  "
                >
                  <img :src="state.entityLogos[item].Url" />
                </div>
              </template>
              <div class="col-auto pt-1 footer-logo">
                <img
                  src="https://insuremytrans.tech/images/Logo/powered-by.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <payment
      v-if="state.paymentModal"
      :state="state"
      :stripe="stripe"
      :premiumToPay="premiumToPay"
      :tenantId="tenantId"
      :contract="contract"
      :distributionLineId="distributionLineId"
      :userEmail="userEmail"
      :orderSucceeded="orderSucceeded"
      :paymentMethod="paymentMethod"
      :externalCustomerId="externalCustomerId"
      :externalOrderId="externalOrderId"
      @setOrderSucceeded="orderSucceeded = $event"
      @setPaymentMethod="paymentMethod = $event"
      @order-succeeded="$emit('order-succeeded', $event)"
    />
  </div>
</template>

<script>
import Vue from "vue";
import globalConfig from "@/global.config.js";
import { i18n } from "./plugins/i18n";
import "./plugins/vee-validate";
import axios from "axios";
import moment from "moment-timezone";
import MissingInformation from "@/components/MissingInformation";
import AdditionalInformation from "@/components/AdditionalInformation";
import QuoteConfiguration from "@/components/QuoteConfiguration";
import Deductible from "@/components/Deductible";
import Confirmation from "@/components/Confirmation";
import CustomerDetails from "@/components/CustomerDetails";
import CustomWizardStep from "@/components/CustomWizardStep";
import Payment from "@/components/Payment";
import { loadStripe } from "@stripe/stripe-js/pure";
import { ResizeObserver as Polyfill } from "@juggle/resize-observer";
const ResizeObserver = window.ResizeObserver || Polyfill;
export default {
  i18n,
  name: "App",
  components: {
    MissingInformation,
    AdditionalInformation,
    QuoteConfiguration,
    Deductible,
    Confirmation,
    CustomerDetails,
    CustomWizardStep,
    Payment,
  },
  props: {
    tenantId: {
      type: String,
      required: true,
    },
    distributionLineId: {
      type: Number,
      required: true,
    },
    userEmail: {
      type: String,
      required: true,
    },
    processPayment: {
      type: Boolean,
      required: false,
    },
    externalCustomerId: {
      type: String,
      required: false,
    },
    externalOrderId: {
      type: String,
      required: false,
    },
    transportMode: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    purchasedByThirdParty: {
      type: Boolean,
      required: false,
    },
    isCreatingManually: {
      type: Boolean,
      required: false,
    },
    inputs: {
      type: Object,
      default() {
        return {};
      },
    },
    customerDetails: {
      type: Object,
      default() {
        return {};
      },
    },
    additionalQuestions: {
      type: Object,
      default() {
        return {};
      },
    },
    purchasedBy: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      apiGeneral: null,
      apiUsers: null,
      modal: false,
      orderSucceeded: false,
      paymentMethod: null,
      stripe: null,
      loadingQuote: true,
      addInsuranceIsLoading: false,
      result: null,
      state: {
        token: "",
        selectedSelfrisk: 0,
        isValidationError: false,
        acceptedImtTerms: false,
        acceptedTermsAndConditions: false,
        acceptedRestrictions: false,
        syncOrder: false,
        customerId: null,
        stripePaymentSecrets: {},
        stripeCustomerId: null,
        stripePaymentMethodId: null,
        selectedStripePaymentMethod: "new",
        stripeCardPaymentMethods: [],
        paymentModal: false,
        invalidCustomerId: false,
        userClientRelations: {},
        entityLogos: {},
        tenantSettings: {},
        orderPayload: {},
      },
      missingRequired: false,
      contract: null,
      countryStates: {},
      inputsToEdit: null,
      inputsUpdated: null,
      additionalsToEdit: null,
      additionalUpdated: null,
      customerDetailsToEdit: null,
      customerDetailsUpdated: null,
      hideMissingInformation: false,
      hideAdditionalInformation: false,
      hideCustomerDetails: false,
      purchasedByThirdPartyCountry: null,
      bannerWidth: 0,
    };
  },
  async mounted() {
    if (
      !this.distributionLineId ||
      !this.tenantId ||
      !this.userEmail ||
      !this.transportMode ||
      !this.currency
    ) {
      this.missingRequired = true;
      return;
    }
    if (
      this.processPayment &&
      (!this.externalCustomerId || !this.externalOrderId)
    ) {
      this.missingRequired = true;
      return;
    }
    this.setAxiosHeaders();
    this.instantiateApis();
    await this.getToken();
    await this.loadTenantSettings();
    await this.loadContract();
    this.loadUserClientRelations();
    this.loadEntityLogos();
    this.loadCountryStates();
    this.setLocale();
    this.initResizeObserver();
    this.configureStripe();
    this.$emit("ready");
  },
  methods: {
    async getToken() {
      const response = await this.apiGeneral.get("token/quote-comp");
      this.state.token = response.data;
    },
    async configureStripe() {
      if (
        this.processPayment &&
        this.contract.ContractData.Data.ContractSettings.PaymentMethod !== 3
      ) {
        const paymentCollector =
          this.contract.ContractData.Data.ContractPartners.find(
            (partner) =>
              partner.Id ===
              this.contract.ContractData.Data.ContractSettings.PaymentCollector
          );
        this.stripe = await loadStripe(process.env.VUE_APP_StripeSecret, {
          stripeAccount: paymentCollector.CompanyDetails.StripeAccountId,
        });
      }
    },
    handleError(error) {
      if (error.response) {
        alert(error.response.data.Message);
      } else {
        alert("Error: " + error.message);
      }
    },
    initResizeObserver() {
      const ro = new ResizeObserver((entries) => {
        window.requestAnimationFrame(() => {
          for (let entry of entries) {
            const { width } = entry.contentRect;
            this.bannerWidth = width;
          }
        });
      });
      ro.observe(this.$refs.banner);
    },
    setLocale() {
      this.$i18n.locale = this.contract.DocumentsLanguage;
    },
    setAxiosHeaders() {
      const keyTenant = "TenantId";
      const keyContentType = "Content-Type";
      const keyControlAllowOrigin = "Access-Control-Allow-Origin";
      axios.defaults.headers.common[keyContentType] =
        "application/json;charset=UTF-8";
      axios.defaults.headers.common[keyControlAllowOrigin] = "*";
      axios.defaults.headers.common[keyTenant] = this.tenantId;
    },
    instantiateApis() {
      this.apiUsers = axios.create({
        baseURL: globalConfig.ApiUsers,
      });
      this.apiGeneral = axios.create({
        baseURL: globalConfig.ApiGeneral,
      });
    },
    async loadTenantSettings() {
      const response = await this.apiGeneral.get("SettingsGeneral/1");
      this.state.tenantSettings = response.data;
    },
    async loadContract() {
      const response = await this.apiGeneral.get(
        "Contract/" + this.distributionLineId
      );
      this.contract = response.data;
    },
    loadEntityLogos() {
      this.apiUsers
        .get(
          "Tenant/logo/" +
            this.contract.ContractData.Data.ContractSettings.ClientManager
        )
        .then((response) => {
          Vue.set(
            this.state.entityLogos,
            this.contract.ContractData.Data.ContractSettings.ClientManager,
            response.data
          );
        });
      this.contract.ContractData.Data.ContractSettings.LogoEntities.forEach(
        async (id) => {
          const response = await this.apiUsers.get("Tenant/logo/" + id);
          Vue.set(this.state.entityLogos, id, response.data);
        }
      );
    },
    loadCountryStates() {
      const apiCountry = axios.create({
        baseURL: globalConfig.ApiCountry,
      });
      apiCountry.get("CountryStates/as-dictionary").then((response) => {
        this.countryStates = response.data;
      });
    },
    loadUserClientRelations() {
      this.apiUsers
        .get(
          "account/user-client-relations/" +
            this.userEmail +
            "/" +
            this.contract.ContractData.Data.ContractSettings.ClientManager
        )
        .then((response) => {
          this.state.userClientRelations = response.data;
          if (
            !this.contract.EndClientHasAccessToAccount ||
            this.purchasedByThirdParty
          ) {
            return;
          }
          this.setCustomerConnection();
        });
    },
    setCustomerConnection() {
      if (
        this.customerDetails.CompanyName &&
        this.state.userClientRelations.CustomerConnections &&
        this.state.userClientRelations.CustomerConnections.length > 0
      ) {
        const customerConnection =
          this.state.userClientRelations.CustomerConnections.find(
            (customer) =>
              customer.CompanyName === this.customerDetails.CompanyName
          );
        if (customerConnection) {
          this.state.customerId = customerConnection.CustomerId;
        } else {
          this.state.customerId =
            this.state.userClientRelations.CustomerConnections[0].CustomerId;
        }
        this.state.syncOrder = true;
      }
    },
    async onComplete() {
      const isValid = await this.$refs.confirmation.$refs.content.validate();
      if (
        this.purchasedByThirdParty &&
        !this.purchasedBy.CompanyNo &&
        !this.purchasedBy.VatNo &&
        !this.purchasedBy.PartnerId
      ) {
        alert(
          "Third Party is purchasing the insurance, yet purchasedBy-information are missing. Contact provider for help."
        );
        return;
      }
      if (!this.readyForOrder || !isValid || this.orderSucceeded) {
        return;
      }
      if (!this.state.syncOrder) {
        this.state.customerId = null;
      }
      this.addInsuranceIsLoading = true;
      this.state.orderPayload = this.getOrderPayload();
      if (this.inputsToEdit.CustomerType === 1) {
        this.state.invalidCustomerId = false;
        const validationPayload = {
          CustomerId: this.state.customerId,
          CustomerEmail: this.state.orderPayload.CustomerDetails.Email,
          CompanyName: this.state.orderPayload.CustomerDetails.CompanyName,
          CompanyNumber: this.state.orderPayload.CustomerDetails.CompanyNumber,
          VatNumber: this.state.orderPayload.CustomerDetails.VatNumber,
          Address: this.state.orderPayload.CustomerDetails.Address,
          City: this.state.orderPayload.CustomerDetails.City,
          PostalCode: this.state.orderPayload.CustomerDetails.PostalCode,
          Country: this.state.orderPayload.CustomerDetails.Country,
          PurchasedByPartnerFour:
            this.purchasedByThirdParty &&
            (!!this.purchasedBy.CompanyNo || !!this.purchasedBy.VatNo),
          PurchasedByCompanyNo: this.purchasedBy.CompanyNo,
          PurchasedByVatNo: this.purchasedBy.VatNo,
          PurchasedByCompanyCountry: this.purchasedBy.CompanyCountryCode,
          UseSanctionCheck:
            this.contract.ContractData.Data.ContractSettings.UseSanctionCheck,
          SanctionCheckMatchIsReferral:
            this.contract.ContractData.Data.ContractSettings
              .SanctionCheckMatchIsReferral,
        };
        this.apiUsers
          .post("CustomerCompany/validate", validationPayload)
          .then((result) => {
            this.state.orderPayload.SanctionCheckId = result.data;
            if (this.processPayment) {
              this.state.paymentModal = true;
            } else {
              this.emitAddedInsurance();
              this.modal = false;
            }
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data.Message === "invalid-customerid"
            ) {
              this.state.invalidCustomerId = true;
            } else {
              this.handleError(error);
            }
          })
          .finally(() => {
            this.addInsuranceIsLoading = false;
          });
      } else {
        const validationPayload = {
          CustomerEmail: this.userEmail,
          Firstname: this.state.orderPayload.CustomerDetails.Firstname,
          Lastname: this.state.orderPayload.CustomerDetails.Lastname,
          Country: this.inputsToEdit.Country,
          PurchasedByPartnerFour:
            this.purchasedByThirdParty &&
            (!!this.purchasedBy.CompanyNo || !!this.purchasedBy.VatNo),
          PurchasedByCompanyNo: this.purchasedBy.CompanyNo,
          PurchasedByVatNo: this.purchasedBy.VatNo,
          PurchasedByCompanyCountry: this.purchasedBy.CompanyCountryCode,
          UseSanctionCheck:
            this.contract.ContractData.Data.ContractSettings.UseSanctionCheck,
          SanctionCheckMatchIsReferral:
            this.contract.ContractData.Data.ContractSettings
              .SanctionCheckMatchIsReferral,
        };
        this.apiUsers
          .post("CustomerPrivate/validate", validationPayload)
          .then((result) => {
            this.state.orderPayload.SanctionCheckId = result.data;
            if (this.processPayment) {
              this.state.paymentModal = true;
            } else {
              this.emitAddedInsurance();
              this.modal = false;
            }
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.addInsuranceIsLoading = false;
          });
      }
    },
    emitAddedInsurance() {
      this.$emit("added-insurance", {
        payload: this.state.orderPayload,
        premiumToPay: this.premiumToPay,
      });
    },
    getOrderPayload() {
      const payload = {
        TimeZone: moment.tz.guess(),
        UserEmail: this.userEmail,
        PurchasedByPartnerId: this.purchasedBy.PartnerId,
        PurchasedByThirdParty: this.purchasedByThirdParty,
        IsCreatingManually: this.isCreatingManually,
        ExternalCustomerId: this.externalCustomerId,
        ExternalOrderId: this.externalOrderId,
        PaymentMethod: this.paymentMethod,
        CustomerId: this.state.customerId,
        QuoteInput: this.inputsToEdit,
        QuoteResult: this.result.quoteResult,
        QuoteResultInLocalCurrency: this.result.quoteResultInLocalCurrency,
        SelectedDeductibleIndex: this.state.selectedSelfrisk,
        CustomerDetails: this.customerDetailsToEdit,
        AdditionalQuestions: this.additionalsToEdit,
        ContractData: this.contract.ContractData.Data,
        Terms: {
          PlatformTermsOfBusiness:
            this.state.userClientRelations.PlatformSettings.TermsOfBusiness,
          PlatformPrivacyPolicy:
            this.state.userClientRelations.PlatformSettings.PrivacyPolicy,
          InsurerTermsOfBusiness:
            this.state.userClientRelations.Insurer.TermsOfBusiness,
          InsurerPrivacyPolicy:
            this.state.userClientRelations.Insurer.PrivacyPolicy,
          ClientManagerTermsOfBusiness:
            this.state.userClientRelations.ClientHandler.TermsOfBusiness,
          ClientManagerPrivacyPolicy:
            this.state.userClientRelations.ClientHandler.PrivacyPolicy,
        },
      };
      payload.CustomerDetails.Email =
        payload.CustomerDetails.Email ?? this.userEmail;
      payload.CustomerDetails.Country = this.result.input.Country;
      payload.CustomerDetails.State = this.result.input.State;
      return payload;
    },
    getQuotePayload() {
      this.inputsToEdit.UserEmail = this.userEmail;
      this.inputsToEdit.PurchasedByThirdPartyCountry = this
        .purchasedByThirdParty
        ? this.purchasedByThirdPartyCountry
        : null;
      const payload = {
        QuoteInput: this.inputsToEdit,
        CustomerDetails: this.customerDetailsToEdit,
        AdditionalQuestions: this.additionalsToEdit,
      };
      payload.CustomerDetails.Country = this.inputsToEdit.Country;
      payload.CustomerDetails.State = this.inputsToEdit.State;
      return payload;
    },
    async getQuote() {
      this.loadingQuote = true;
      this.state.isValidationError = false;
      this.result = null;

      const isValid = await this.validateSecondaryInputs();
      if (!isValid) {
        this.loadingQuote = false;
        return;
      }

      const payload = this.getQuotePayload();
      this.apiGeneral
        .post("quote", payload)
        .then((response) => {
          this.state.selectedSelfrisk = 0;
          this.result = response.data;
        })
        .catch((error) => {
          this.result = null;
          this.state.isValidationError =
            error.response.data.title?.includes("validation") ?? false;
          if (!this.state.isValidationError) {
            this.$refs.form.reset();
          }
        })
        .finally(() => {
          this.setInsuranceType();
          this.loadingQuote = false;
        });
    },
    async validateSecondaryInputs() {
      try {
        await this.apiGeneral.post("quote/validate", this.inputsToEdit);
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    hideMissing() {
      this.hideMissingInformation = true;
      this.$refs.form.nextTab();
    },
    hideAdditional() {
      this.hideAdditionalInformation = true;
      this.$refs.form.nextTab();
    },
    hideCustomer() {
      this.hideCustomerDetails = true;
      this.$refs.form.nextTab();
    },
    resetAdditional() {
      this.hideAdditionalInformation = false;
      this.additionalsToEdit = JSON.parse(
        JSON.stringify(this.additionalQuestions)
      );
      this.additionalUpdated = new Date().getTime();
    },
    resetCustomerDetails() {
      this.hideCustomerDetails = false;
      this.customerDetailsToEdit = JSON.parse(
        JSON.stringify(this.customerDetails)
      );
      this.customerDetailsUpdated = new Date().getTime();
    },
    resetMissing(val) {
      this.hideMissingInformation = false;
      this.inputsToEdit = JSON.parse(JSON.stringify(val));
      this.setInsuranceType();
      this.inputsToEdit.DistributionLineId = this.distributionLineId;
      this.inputsToEdit.TransportMode = this.transportMode;
      this.inputsToEdit.ValueOfGoods = this.inputsToEdit.ValueOfGoods ?? 0;
      this.inputsToEdit.TransportationCharges =
        this.inputsToEdit.TransportationCharges ?? 0;
      this.inputsToEdit.Currency = this.currency;
      this.inputsToEdit.AdditionalClauses = [];
      this.inputsUpdated = new Date().getTime();
    },
    getQuestionByTransportMode(val) {
      if (!this.contract) {
        return {};
      }
      return this.contract.ContractData.Data.ContractQuestions.find(
        (q) => q.OptionsApi === val
      ).Options.find((o) => o.TransportModeId === this.transportMode);
    },
    setInsuranceType() {
      if (this.result && this.result.quoteResult) {
        for (var key in this.result.quoteResult.QuotesByInsuranceType) {
          this.inputsToEdit.InsuranceType = parseInt(key);
          return;
        }
      }
      const selectedInsuranceType = this.inputsToEdit.InsuranceType ?? 1;
      this.$set(this.inputsToEdit, "InsuranceType", selectedInsuranceType);
      if (this.insuranceTypes[selectedInsuranceType - 1].Selected === 2) {
        this.inputsToEdit.InsuranceType = selectedInsuranceType;
        return;
      } else {
        if (this.insuranceTypes[0].Selected === 2) {
          this.inputsToEdit.InsuranceType = 1;
        } else if (this.insuranceTypes[1].Selected === 2) {
          this.inputsToEdit.InsuranceType = 2;
        } else {
          this.inputsToEdit.InsuranceType = 3;
        }
      }
    },
    async validateAdditional() {
      const isValid =
        await this.$refs.additionalInformation.$refs.content.validate();
      return isValid;
    },
    async validateMissing() {
      const isValid =
        await this.$refs.missingInformation.$refs.content.validate();
      if (isValid) {
        this.getQuote();
      }
      return isValid;
    },
    async validateCustomer() {
      const isValid = await this.$refs.customerDetails.$refs.content.validate();
      return isValid;
    },
    async validateConfirmation() {
      const isValid = await this.$refs.confirmation.$refs.content.validate();
      return isValid;
    },
  },
  computed: {
    insuranceTypes() {
      return this.getQuestionByTransportMode("InsuranceType").Items;
    },
    premiumToPay() {
      if (!this.result || !this.inputsToEdit) return null;

      const insuranceType = this.inputsToEdit.InsuranceType;

      if (
        this.result.quoteResult.QuotesByInsuranceType[insuranceType] &&
        this.result.quoteResult.QuotesByInsuranceType[insuranceType]
          .PremiumsBySelfrisk &&
        this.result.quoteResult.QuotesByInsuranceType[insuranceType]
          .PremiumsBySelfrisk[this.state.selectedSelfrisk] &&
        this.result.quoteResult.QuotesByInsuranceType[insuranceType]
          .PremiumsBySelfrisk[this.state.selectedSelfrisk].PremiumToPay
      ) {
        let localAmount, localCurrency;
        if (this.result.quoteResultInLocalCurrency) {
          localAmount =
            this.result.quoteResultInLocalCurrency.QuotesByInsuranceType[
              insuranceType
            ].PremiumsBySelfrisk[
              this.state.selectedSelfrisk
            ].PremiumToPay.toFixed(2);
          localCurrency = this.result.quoteResultInLocalCurrency.Currency;
        }
        return {
          localAmount: localAmount,
          localCurrency: localCurrency,
          amount:
            this.result.quoteResult.QuotesByInsuranceType[
              insuranceType
            ].PremiumsBySelfrisk[
              this.state.selectedSelfrisk
            ].PremiumToPay.toFixed(2),
          currency: this.result.quoteResult.Currency,
        };
      } else if (
        this.result.quoteResult.QuotesByInsuranceType[insuranceType] &&
        this.result.quoteResult.QuotesByInsuranceType[insuranceType]
          .PremiumToPayNoSelfrisk
      ) {
        let localAmount, localCurrency;
        if (this.result.quoteResultInLocalCurrency) {
          localAmount =
            this.result.quoteResultInLocalCurrency.QuotesByInsuranceType[
              insuranceType
            ].PremiumToPayNoSelfrisk.toFixed(2);
          localCurrency = this.result.quoteResultInLocalCurrency.Currency;
        }
        return {
          localAmount: localAmount,
          localCurrency: localCurrency,
          amount:
            this.result.quoteResult.QuotesByInsuranceType[
              insuranceType
            ].PremiumToPayNoSelfrisk.toFixed(2),
          currency: this.result.quoteResult.Currency,
        };
      } else {
        return null;
      }
    },
    clientManagerLogo() {
      if (!this.contract) {
        return undefined;
      }
      if (
        this._.isEmpty(
          this.state.entityLogos[
            this.contract.ContractData.Data.ContractSettings.ClientManager
          ]
        )
      ) {
        return undefined;
      }
      return this.state.entityLogos[
        this.contract.ContractData.Data.ContractSettings.ClientManager
      ].UrlLight;
    },
    allTermsAccepted() {
      if (
        ((this.hasGeneralTerms && this.state.acceptedGeneralTerms) ||
          !this.hasGeneralTerms) &&
        this.state.acceptedInsuranceTerms &&
        ((this.hasRestrictions && this.state.acceptedRestrictions) ||
          !this.hasRestrictions)
      ) {
        return true;
      } else {
        return false;
      }
    },
    hasGeneralTerms() {
      return (
        !this.state.userClientRelations.AgreedPlatformTerms ||
        !this.state.userClientRelations.AgreedInsurerTerms ||
        !this.state.userClientRelations.AgreedClientHandlerTerms
      );
    },
    hasRestrictions() {
      if (this.result && this.result.quoteResult) {
        for (const key in this.result.quoteResult.Restrictions) {
          if (
            Object.prototype.hasOwnProperty.call(
              this.result.quoteResult.Restrictions,
              key
            )
          ) {
            const element = this.result.quoteResult.Restrictions[key];
            if (element.length > 0) {
              return true;
            }
          }
        }
      }
      return false;
    },
    hideAdminCurrency() {
      if (this.result && this.result.quoteResultInLocalCurrency) {
        const currency = this.result.quoteResultInLocalCurrency.Currency;
        if (
          this.contract.ContractData.Data.ContractCustomerFees.ExemptCurrencies.indexOf(
            currency
          ) !== -1
        ) {
          return true;
        }
      }
      return false;
    },
    readyForOrder() {
      if (
        !this.result ||
        !this.result.quoteResult.QuotesByInsuranceType[
          this.inputsToEdit.InsuranceType
        ] ||
        this.result.quoteResult.QuotesByInsuranceType[
          this.inputsToEdit.InsuranceType
        ].Declined ||
        !this.allTermsAccepted
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    processPayment() {
      this.configureStripe();
    },
    async distributionLineId() {
      await this.loadContract();
      this.resetMissing(this.inputs);
      this.loadUserClientRelations();
      this.loadEntityLogos();
      this.setLocale();
      this.getQuote();
    },
    userEmail() {
      this.loadUserClientRelations();
    },
    transportMode() {
      this.resetMissing(this.inputs);
    },
    currency() {
      this.resetMissing(this.inputs);
      this.getQuote();
    },
    inputs: {
      handler(val) {
        this.orderSucceeded = false;
        this.resetMissing(val);
        this.getQuote();
      },
      deep: true,
    },
    "inputsToEdit.CustomerType"() {
      this.loadUserClientRelations();
      this.resetCustomerDetails();
    },
    additionalQuestions: {
      handler() {
        this.resetAdditional();
      },
      deep: true,
      immediate: true,
    },
    customerDetails: {
      handler() {
        this.resetCustomerDetails();
        this.setCustomerConnection();
      },
      deep: true,
      immediate: true,
    },
    "purchasedBy.CompanyCountryCode"(val) {
      this.purchasedByThirdPartyCountry = val;
      this.getQuote();
    },
    purchasedByThirdParty() {
      this.loadUserClientRelations();
    },
  },
};
</script>

<style lang="scss">
@import url("https://insuremytrans.tech/fonts/font-awesome/css/font-awesome.css");
@import "./scss/uastylesheet.scss";
@import "./scss/bootstrap.scss";
@import "./scss/bootstrap-vue.scss";
@import "./scss/vue-form-wizard.scss";
@import "./scss/vue-select.scss";
/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans_Custom";
  font-style: normal;
  font-weight: 400;
  src: url("https://insuremytrans.tech/fonts/open-sans/open-sans-v17-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    /* IE6-IE8 */
      url("https://insuremytrans.tech/fonts/open-sans/open-sans-v17-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* Modern Browsers */
      url("https://insuremytrans.tech/fonts/open-sans/open-sans-v17-latin-regular.woff")
      format("woff"),
    /*  Safari, Android, iOS */
      url("https://insuremytrans.tech/fonts/open-sans/open-sans-v17-latin-regular.ttf")
      format("truetype"),
    /* Legacy iOS */
      url("https://insuremytrans.tech/fonts/open-sans/open-sans-v17-latin-regular.svg#OpenSans")
      format("svg");
}
.imt-quote {
  font-family: "Open Sans_Custom";
  font-size: 16px;
  line-height: 1.5;
  color: #000;
  text-align: left;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Open Sans_Custom";
    color: #000;
  }
  a,
  a:hover,
  a:active {
    line-height: 1.5;
    color: #f15d22;
  }
  a:hover,
  a:active {
    text-decoration: underline;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    background: #fff;
    height: 7px;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  /* Bootstrap columns fix for safari */
  .row:before,
  .row:after {
    display: inline-block;
  }
  /* End */
  .input-group .form-control {
    padding: 6px 12px;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #ff772d;
    background-color: #ff772d;
  }
  .stripe-card-group {
    font-family: Arial, sans-serif;
    font-size: 16px;
    .stripe-card-row {
      padding-left: 12px;
      i {
        color: green;
      }
      .text-small {
        font-size: 14px;
        color: #464646;
      }
    }
    label {
      display: inline;
    }
    .custom-control {
      padding-bottom: 10px;
      padding-top: 10px;
      @media (min-width: 768px) {
        padding-left: 5.5rem;
        padding-right: 5.5rem;
      }
    }
    .custom-control:not(:last-child) {
      border-bottom: 1px solid rgba(50, 50, 93, 0.1);
    }
    .custom-control-label:before,
    .custom-control-label:after {
      top: 1rem;
    }
  }
  .bg-orange {
    background-color: #ff772d !important;
    border-radius: 0 !important;
    border: 1px solid #ff772d;
    border: none;
  }
  .btn-orange,
  .btn-orange:hover {
    text-transform: uppercase;
    border: none;
    background: linear-gradient(
      117deg,
      #fe8607,
      #fe8607 0,
      #f66e24 37%,
      #ef5536 59%,
      #e84144 75%,
      #da1c5f
    );
    color: #fff;
    padding: 3px 20px;
    min-width: 100px;
    font-weight: bold;
    font-size: 16px;
    @media (min-width: 351px) {
      min-height: 59px;
      border-radius: 0 !important;
    }
  }
  .btn-orange-small,
  .btn-orange-small:hover {
    text-transform: uppercase;
    border: none;
    background: linear-gradient(
      117deg,
      #fe8607,
      #fe8607 0,
      #f66e24 37%,
      #ef5536 59%,
      #e84144 75%,
      #da1c5f
    );
    color: #fff;
    padding: 5px 20px;
    min-width: 100px;
    font-weight: bold;
    font-size: 14px;
    > span {
      color: #fff;
    }
  }
  .btn-mint,
  .btn-mint:hover {
    text-transform: uppercase;
    background-color: #3bb692;
    color: #fff;
    padding: 3px 20px;
    min-width: 100px;
    font-weight: bold;
    font-size: 16px;
    > span,
    > svg > g > path {
      color: #fff;
    }
    @media (min-width: 351px) {
      min-height: 59px;
      border-radius: 0 !important;
    }
  }
  .btn-outline-orange {
    color: #ff772d;
    border-color: #ff772d;
    padding: 3px 20px;
    min-width: 100px;
    font-weight: bold;
    font-size: 16px;
  }
  .btn-outline-orange:hover {
    background-color: #ff772d;
    color: #fff;
  }
  .btn-white {
    text-transform: uppercase;
    color: #1e3f5a;
    background-color: #fff;
    padding: 3px 20px;
    min-width: 100px;
    font-weight: bold;
    font-size: 16px;
    @media (min-width: 351px) {
      min-height: 59px;
      border-radius: 0 !important;
      border: none;
      border-top: solid 1px #1e3f5a;
      border-bottom: solid 1px #1e3f5a;
    }
  }
  .btn-white:hover {
    background: linear-gradient(
      117deg,
      #fe8607,
      #fe8607 0,
      #f66e24 37%,
      #ef5536 59%,
      #e84144 75%,
      #da1c5f
    );
    border: none;
    color: #fff;
  }
  .sheen {
    position: relative;
    overflow: hidden;
  }
  .sheen:after {
    animation: sheen 3s ease-in-out infinite;
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(5deg);

    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.13) 0%,
      rgba(255, 255, 255, 0.13) 77%,
      rgba(255, 255, 255, 0.5) 92%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  @keyframes sheen {
    10% {
      opacity: 1;
      top: -30%;
      left: -30%;
      transition-property: left, top, opacity;
      transition-duration: 0.7s, 0.7s, 0.15s;
      transition-timing-function: ease;
    }
    100% {
      opacity: 0;
      top: -30%;
      left: -30%;
      transition-property: left, top, opacity;
    }
  }
  @-webkit-keyframes sheen {
    10% {
      opacity: 1;
      top: -30%;
      left: -30%;
      -webkit-transition-property: left, top, opacity;
      -webkit-transition-duration: 0.7s, 0.7s, 0.15s;
      -webkit-transition-timing-function: ease;
    }
    100% {
      opacity: 0;
      top: -30%;
      left: -30%;
      -webkit-transition-property: left, top, opacity;
    }
  }
  .spinner-border-small {
    height: 20px;
    width: 20px;
  }
  .table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
  .bg-imt-quote {
    background-color: #1e3f5a !important;
  }
  &.tooltip {
    z-index: 2500;
  }
  .tooltip-inner {
    background-color: #f15d22 !important;
  }
  .content-area {
    padding: 10px 10px 0 0;
    text-align: left;
  }
  .b-form-date-calendar {
    footer {
      display: none;
    }
  }
  .vs__dropdown-menu {
    max-height: 200px;
    .vs__dropdown-option--highlight {
      background: #f15d22;
    }
  }
  .form-tab-container {
    min-height: calc(100vh - 314px);
    position: relative;
    margin: 0 auto;
    padding: 2rem;
    background: #fff;
    @media (min-width: 768px) {
      min-height: calc(100vh - 264px);
    }
    @media (min-width: 576px) {
      width: 100%;
      padding-top: 40px;
      padding-left: 90px;
      padding-right: 90px;
    }
  }
  .premium-text-bottom {
    font-size: 20px;
    color: #fff;
    text-align: center;
    .spinner-border {
      width: 35px;
      height: 35px;
    }
  }
  .vue-form-wizard {
    padding: 0;
    .wizard-progress-with-circle {
      display: none;
    }
    .wizard-header {
      padding: 0;
    }
    .wizard-icon-circle.tab_shape {
      height: 60px !important;
      background-color: #1e3f5a;
      font: 600 16px "Open Sans_Custom";
      align-items: center;
      color: #fff;
    }
    .wizard-icon-container.tab_shape {
      height: 100%;
      align-items: center;
    }
    .wizard-tab-content {
      overflow: auto;
      height: calc(100vh - 314px);
      @media (min-width: 768px) {
        height: calc(100vh - 264px);
      }
      padding: 0px;
    }
    .wizard-nav-pills {
      padding-bottom: 5px;
      li:not(.active) {
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }
    .wizard-icon-container:after {
      content: "";
      bottom: -2px;
      margin: 0 auto;
      position: absolute;
      border-bottom: 2px solid #fff;
      width: 19px;
    }
    .wizard-nav-pills li.active a:before,
    .wizard-nav-pills li.active a:after {
      content: "";
      width: 15px;
      height: 15px;
      position: absolute;
      border: solid #1e3f5a;
      border-width: 2px 2px 0 0;
      bottom: -8px;
      -moz-transform: rotate(315deg);
      -webkit-transform: rotate(315deg);
      -ms-transform: rotate(315deg);
    }
    .stepTitle {
      font-size: 16px;
      color: #fff;
      &.has_error {
        color: #dc3545 !important;
      }
    }
    .wizard-card-footer {
      // padding: 10px 205px 10px 200px;
      // padding-top: 10px;
      // padding-bottom: 10px;
      padding: 0;
      background-color: transparent;
      z-index: 1;
      position: relative;
      @media screen and (max-width: 1250px) {
        // padding: 10px 100px 10px 90px;
      }
      @media (max-width: 576px) {
        // padding: 10px 50px 10px 50px;
      }
      @media (max-width: 350px) {
        flex-direction: row;
        .wizard-footer-right,
        .wizard-footer-left {
          align-items: flex-end;
        }
        // padding: 10px 20px 10px 20px;
      }
    }
  }
  .clickable {
    cursor: pointer;
  }
  h4 {
    &.premium {
      margin-bottom: 0;
      font-size: 20px;
      text-align: center;
      display: initial;
      > span {
        line-height: 2em;
      }
    }
  }
  .banner.banner-vert {
    .banner-left,
    .banner-middle,
    .banner-right {
      text-align: center;
    }
  }
  .banner {
    .banner-left {
      text-align: left;
    }
    .banner-middle {
      text-align: center;
    }
    .banner-right {
      text-align: right;
    }
    &.card {
      min-width: 250px;
      background-color: #1e3f5a;
      border: 5px solid #1e3f5a;
      border-radius: 0.3rem;
      .card-body {
        padding: 0;
      }
      .card-footer {
        padding: 10px;
        background-color: #fff;
      }
    }
  }
  .logo {
    max-height: 38px;
    max-width: 100%;
  }
  .powered-by {
    position: absolute;
    bottom: 2px;
    right: 5px;
    font-size: 10px;
  }
  .custom-modal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 2000; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100vh; /* Full vh-height */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }
  /* Modal Content */
  .custom-modal-content {
    position: relative;
    border-radius: 10px;
    background-color: #1e3f5a;
    margin: 0 auto;
    max-width: 1530px;
    padding: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    animation-name: animatetop;
    animation-duration: 0.4s;
    /* Modal Header */
    .custom-modal-header {
      padding: 16px 30px;
      border-radius: 5px 5px 0 0;
      background-color: #1e3f5a;
      color: white;
      display: flex;
      align-items: center;
      .header-logo {
        position: fixed;
        top: 20px;
        img {
          height: 38px;
        }
      }
      .header-text {
        font-size: 20px;
        font-weight: bold;
        flex-grow: 1;
        text-align: center;
        @media (max-width: 576px) {
          visibility: hidden;
        }
      }
      .white-close {
        opacity: 1 !important;
        .fa {
          text-shadow: 0 1px 0 #1e3f5a !important;
          color: #fff !important;
        }
      }
    }
    /* Modal Body */
    .custom-modal-body {
      padding: 0px 10px;
      background-color: #1e3f5a;
      &.fixed-height {
        height: calc(100vh - 190px);
        @media (min-width: 768px) {
          height: calc(100vh - 140px);
        }
      }
    }
    /* Modal premium to pay */
    .custom-modal-premium {
      height: 50px;
      bottom: 70px;
      text-align: center;
      width: 100%;
      background: transparent;
      color: white;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (min-width: 768px) {
        position: absolute;
      }
    }
    /* Modal Footer */
    .custom-modal-footer {
      padding: 10px;
      padding-top: 0;
      height: 70px;
      border-radius: 0 0 5px 5px;
      background-color: #1e3f5a;
      color: white;
    }
  }
  .secondary-modal {
    .card.payment-method-card {
      border: 1px solid #95989a;
      border-radius: 14px;
      font-size: 16px;
      letter-spacing: 0px;
      height: 70px;
      margin: 2px;
      .card-body {
        padding: 0.25em;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .title {
        font-weight: bold;
        color: #231f20;
      }
      .price {
        font-weight: bolder;
        color: #231f20;
      }
      &.selected {
        border: 4px solid #ff772d;
      }
    }
    .custom-modal-content .custom-modal-body {
      background-color: #fff;
      height: calc(100vh - 100px);
      overflow-y: auto;
      h4 {
        font-size: 16px;
      }
    }
    .custom-modal-header {
      .close {
        position: absolute;
        right: 25px;
      }
    }
  }
  .logos-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #fff;
    padding: 0 10px;
    .footer-logo {
      flex-grow: 1;
      text-align: center;
      img {
        margin: 0 auto;
        text-align: center;
        max-height: 30px;
        max-width: 200px;
        @media (max-width: 768px) {
          max-height: 20px;
          max-width: 150px;
        }
      }
    }
  }
  /* Add Animation */
  @keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }
  .stripe {
    /* Variables */
    * {
      box-sizing: border-box;
    }
    body {
      font-family: -apple-system, BlinkMacSystemFont, sans-serif;
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      display: flex;
      justify-content: center;
      align-content: center;
      height: 100vh;
      width: 100vw;
    }
    form {
      width: 30vw;
      min-width: 500px;
      align-self: center;
      box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
        0px 2px 5px 0px rgba(50, 50, 93, 0.1),
        0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
      border-radius: 7px;
      padding: 40px;
    }
    input {
      border-radius: 6px;
      margin-bottom: 6px;
      padding: 12px;
      border: 1px solid rgba(50, 50, 93, 0.1);
      height: 44px;
      font-size: 16px;
      width: 100%;
      background: white;
    }
    .hidden {
      display: none;
    }
    #card-error {
      color: rgb(105, 115, 134);
      text-align: left;
      font-size: 13px;
      line-height: 17px;
      margin-top: 12px;
    }
    #card-element {
      border-radius: 4px 4px 0 0;
      padding: 12px;
      // border: 1px solid rgba(50, 50, 93, 0.1);
      height: 44px;
      width: 100%;
      background: white;
    }
    #payment-request-button {
      margin-bottom: 32px;
    }
    /* Buttons and links */
    button {
      background: #3bb692;
      color: #ffffff;
      font-family: Arial, sans-serif;
      border-radius: 4px;
      border: 0;
      padding: 12px 16px;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      display: block;
      transition: all 0.2s ease;
      box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
      width: 100%;
    }
    button:hover {
      filter: contrast(115%);
    }
    button:disabled {
      opacity: 0.5;
      cursor: default;
    }
    /* spinner/processing state, errors */
    .spinner,
    .spinner:before,
    .spinner:after {
      border-radius: 50%;
    }
    .spinner {
      color: #ffffff;
      font-size: 22px;
      text-indent: -99999px;
      margin: 0px auto;
      position: relative;
      width: 20px;
      height: 20px;
      box-shadow: inset 0 0 0 2px;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
    }
    .spinner:before,
    .spinner:after {
      position: absolute;
      content: "";
    }
    .spinner:before {
      width: 10.4px;
      height: 20.4px;
      background: #3bb692;
      border-radius: 20.4px 0 0 20.4px;
      top: -0.2px;
      left: -0.2px;
      -webkit-transform-origin: 10.4px 10.2px;
      transform-origin: 10.4px 10.2px;
      -webkit-animation: loading 2s infinite ease 1.5s;
      animation: loading 2s infinite ease 1.5s;
    }
    .spinner:after {
      width: 10.4px;
      height: 10.2px;
      background: #3bb692;
      border-radius: 0 10.2px 10.2px 0;
      top: -0.1px;
      left: 10.2px;
      -webkit-transform-origin: 0px 10.2px;
      transform-origin: 0px 10.2px;
      -webkit-animation: loading 2s infinite ease;
      animation: loading 2s infinite ease;
    }
    @-webkit-keyframes loading {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes loading {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @media only screen and (max-width: 600px) {
      form {
        width: 80vw;
      }
    }
  }
}
</style>
