var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"customer-details"},[_c('validation-observer',{ref:"content",attrs:{"tag":"form"}},[_c('div',{staticClass:"row"},[(
          _vm.inputsToEdit.CustomerType === 2 &&
          _vm.contract.ContractData.Data.ContractSettings.CivicIdIsMandatory &&
          (!_vm.customerDetails.CivilServantNumber ||
            !_vm.countryStates.Countries[
              _vm.customerDetails.CountryCivilServantNumber
            ])
        )?[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.civilservant_country')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Name","placeholder":_vm.$t('content.select_country'),"clearable":false,"reduce":function (country) { return country.Alpha3; },"options":_vm.countriesList},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" "+_vm._s(_vm.$t("content.no_match"))+" ")]}}],null,true),model:{value:(_vm.customerDetailsToEdit.CountryCivilServantNumber),callback:function ($$v) {_vm.$set(_vm.customerDetailsToEdit, "CountryCivilServantNumber", $$v)},expression:"customerDetailsToEdit.CountryCivilServantNumber"}}),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2560164713)})],1)])],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.civilservant_number')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customerDetailsToEdit.CivilServantNumber),expression:"customerDetailsToEdit.CivilServantNumber"}],staticClass:"form-control",attrs:{"placeholder":_vm.$t('content.civilservant_number')},domProps:{"value":(_vm.customerDetailsToEdit.CivilServantNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.customerDetailsToEdit, "CivilServantNumber", $event.target.value)}}}),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,146394321)})],1)])],1)]:_vm._e(),(!_vm.customerDetails.Firstname)?_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.firstname')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customerDetailsToEdit.Firstname),expression:"customerDetailsToEdit.Firstname"}],staticClass:"form-control",domProps:{"value":(_vm.customerDetailsToEdit.Firstname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.customerDetailsToEdit, "Firstname", $event.target.value)}}}),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1971967778)})],1)])],1):_vm._e(),(!_vm.customerDetails.Lastname)?_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.lastname')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customerDetailsToEdit.Lastname),expression:"customerDetailsToEdit.Lastname"}],staticClass:"form-control",domProps:{"value":(_vm.customerDetailsToEdit.Lastname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.customerDetailsToEdit, "Lastname", $event.target.value)}}}),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2578616482)})],1)])],1):_vm._e(),(_vm.inputsToEdit.CustomerType === 1 && !_vm.customerDetails.CompanyName)?_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.company_name')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customerDetailsToEdit.CompanyName),expression:"customerDetailsToEdit.CompanyName"}],staticClass:"form-control",domProps:{"value":(_vm.customerDetailsToEdit.CompanyName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.customerDetailsToEdit, "CompanyName", $event.target.value)}}}),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3021303170)})],1)])],1):_vm._e(),(!_vm.customerDetails.Address)?_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.address')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customerDetailsToEdit.Address),expression:"customerDetailsToEdit.Address"}],staticClass:"form-control",domProps:{"value":(_vm.customerDetailsToEdit.Address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.customerDetailsToEdit, "Address", $event.target.value)}}}),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2723857282)})],1)])],1):_vm._e(),(!_vm.customerDetails.City)?_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.city')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customerDetailsToEdit.City),expression:"customerDetailsToEdit.City"}],staticClass:"form-control",domProps:{"value":(_vm.customerDetailsToEdit.City)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.customerDetailsToEdit, "City", $event.target.value)}}}),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1073075106)})],1)])],1):_vm._e(),(!_vm.customerDetails.PostalCode)?_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.postal_code')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customerDetailsToEdit.PostalCode),expression:"customerDetailsToEdit.PostalCode"}],staticClass:"form-control",domProps:{"value":(_vm.customerDetailsToEdit.PostalCode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.customerDetailsToEdit, "PostalCode", $event.target.value)}}}),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4092744194)})],1)])],1):_vm._e(),(
          _vm.inputsToEdit.CustomerType === 1 &&
          !_vm.customerDetails.VatNumber &&
          !_vm.customerDetails.CompanyNumber
        )?[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.vatnr')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":_vm.companyIdRule ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customerDetailsToEdit.VatNumber),expression:"customerDetailsToEdit.VatNumber"}],staticClass:"form-control",domProps:{"value":(_vm.customerDetailsToEdit.VatNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.customerDetailsToEdit, "VatNumber", $event.target.value)}}}),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4056989122)})],1)])],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.companynr')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":_vm.companyIdRule ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customerDetailsToEdit.CompanyNumber),expression:"customerDetailsToEdit.CompanyNumber"}],staticClass:"form-control",domProps:{"value":(_vm.customerDetailsToEdit.CompanyNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.customerDetailsToEdit, "CompanyNumber", $event.target.value)}}}),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,238394690)})],1)])],1)]:_vm._e()],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }