<template>
  <div class="additional-information">
    <validation-observer ref="content" v-if="additionalsToEdit" tag="form">
      <div class="row">
        <div
          class="col-md-6"
          v-if="
            showField('ExpectedInsuranceStartDate') &&
            (!startDateIsValid || !additionalQuestions.ExpectedStartDate)
          "
        >
          <b-form-group :label="$t('content.insurance_startdate')">
            <div class="content-area">
              <validation-provider
                :rules="
                  requiredField('ExpectedInsuranceStartDate') ? 'required' : ''
                "
                v-slot="{ errors }"
              >
                <b-form-datepicker
                  :key="endDateTimeStamp"
                  v-model="additionalsToEdit.ExpectedStartDate"
                  @input="startDateTimeStamp = new Date().getTime()"
                  :hide-header="true"
                  :start-weekday="1"
                  :min="new Date()"
                  :max="additionalsToEdit.ExpectedEndDate"
                  name="StartDate"
                >
                </b-form-datepicker>
                <span class="small text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </b-form-group>
        </div>
        <div
          class="col-md-6"
          v-if="
            showField('ExpectedInsuranceEndDate') &&
            (!endDateIsValid || !additionalQuestions.ExpectedEndDate)
          "
        >
          <b-form-group :label="$t('content.insurance_enddate')">
            <div class="content-area">
              <validation-provider
                :rules="
                  requiredField('ExpectedInsuranceEndDate') ? 'required' : ''
                "
                v-slot="{ errors }"
              >
                <b-form-datepicker
                  :key="startDateTimeStamp"
                  @input="endDateTimeStamp = new Date().getTime()"
                  v-model="additionalsToEdit.ExpectedEndDate"
                  :hide-header="true"
                  :start-weekday="1"
                  :min="additionalsToEdit.ExpectedStartDate"
                  name="EndDate"
                >
                </b-form-datepicker>
                <span class="small text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </b-form-group>
        </div>
        <div
          class="col-md-6"
          v-if="
            showField('DescriptionOfCargo') &&
            !additionalQuestions.CargoDescription
          "
        >
          <b-form-group :label="$t('content.cargo_description')">
            <div class="content-area">
              <validation-provider
                :rules="requiredField('DescriptionOfCargo') ? 'required' : ''"
                v-slot="{ errors }"
              >
                <textarea
                  v-model="additionalsToEdit.CargoDescription"
                  class="form-control"
                />
                <span class="small text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </b-form-group>
        </div>
        <div
          class="col-md-6"
          v-if="
            showField('MarksAndNumbers') && !additionalQuestions.MarksAndNumbers
          "
        >
          <b-form-group :label="$t('content.marks_numbers')">
            <div class="content-area">
              <validation-provider
                :rules="requiredField('MarksAndNumbers') ? 'required' : ''"
                v-slot="{ errors }"
              >
                <input
                  v-model="additionalsToEdit.MarksAndNumbers"
                  class="form-control"
                />
                <span class="small text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </b-form-group>
        </div>
        <div
          class="col-md-6"
          v-if="showField('VesselName') && !additionalQuestions.VesselName"
        >
          <b-form-group :label="$t('content.vessel_name')">
            <div class="content-area">
              <validation-provider
                :rules="requiredField('VesselName') ? 'required' : ''"
                v-slot="{ errors }"
              >
                <input
                  v-model="additionalsToEdit.VesselName"
                  class="form-control"
                />
                <span class="small text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </b-form-group>
        </div>
        <div
          class="col-md-6"
          v-if="showField('Carrier') && !additionalQuestions.Carrier"
        >
          <b-form-group :label="$t('content.carrier')">
            <div class="content-area">
              <validation-provider
                :rules="requiredField('Carrier') ? 'required' : ''"
                v-slot="{ errors }"
              >
                <input
                  v-model="additionalsToEdit.Carrier"
                  class="form-control"
                />
                <span class="small text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </b-form-group>
        </div>
        <div
          class="col-md-6"
          v-if="showField('VoyageNumber') && !additionalQuestions.VoyageNumber"
        >
          <b-form-group :label="$t('content.voyage')">
            <div class="content-area">
              <validation-provider
                :rules="requiredField('VoyageNumber') ? 'required' : ''"
                v-slot="{ errors }"
              >
                <input
                  v-model="additionalsToEdit.VoyageNumber"
                  class="form-control"
                />
                <span class="small text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-6" v-if="showNamedPlaceOfIncoterm()">
          <b-form-group :label="$t('content.named_place')">
            <div class="content-area">
              <validation-provider
                :rules="requiredField('NamedPlaceOfIncoterm') ? 'required' : ''"
                v-slot="{ errors }"
              >
                <input
                  v-model="additionalsToEdit.IncotermNamedPlace"
                  class="form-control"
                />
                <span class="small text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </b-form-group>
        </div>
        <div
          class="col-md-6"
          v-if="showField('OriginCity') && !additionalQuestions.OriginCity"
        >
          <b-form-group :label="$t('content.origin_city')">
            <div class="content-area">
              <validation-provider
                :rules="
                  requiredField('OriginCity')
                    ? 'required|alpha_spaces'
                    : 'alpha_spaces'
                "
                v-slot="{ errors }"
              >
                <input
                  v-model="additionalsToEdit.OriginCity"
                  class="form-control"
                />
                <span class="small text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </b-form-group>
        </div>
        <div
          class="col-md-6"
          v-if="
            showField('LoadingCountry') &&
            !countryStates.Countries[additionalQuestions.LoadingCountry]
          "
        >
          <b-form-group :label="$t('content.loading_country')">
            <div class="content-area">
              <validation-provider
                :rules="requiredField('LoadingCountry') ? 'required' : ''"
                v-slot="{ errors }"
              >
                <v-select
                  label="Name"
                  :placeholder="$t('content.select_country')"
                  v-model="additionalsToEdit.LoadingCountry"
                  :clearable="false"
                  :reduce="(country) => country.Alpha3"
                  :options="countriesList"
                >
                  <template #no-options="{}">
                    {{ $t("content.no_match") }}
                  </template>
                </v-select>
                <span class="small text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </b-form-group>
        </div>
        <div
          class="col-md-6"
          v-if="showField('LoadingCity') && !additionalQuestions.LoadingCity"
        >
          <b-form-group :label="$t('content.loading_city')">
            <div class="content-area">
              <validation-provider
                :rules="
                  requiredField('LoadingCity')
                    ? 'required|alpha_spaces'
                    : 'alpha_spaces'
                "
                v-slot="{ errors }"
              >
                <input
                  v-model="additionalsToEdit.LoadingCity"
                  class="form-control"
                />
                <span class="small text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </b-form-group>
        </div>
        <div
          class="col-md-6"
          v-if="
            showField('DischargeCountry') &&
            !countryStates.Countries[additionalQuestions.DischargeCountry]
          "
        >
          <b-form-group :label="$t('content.discharge_country')">
            <div class="content-area">
              <validation-provider
                :rules="requiredField('DischargeCountry') ? 'required' : ''"
                v-slot="{ errors }"
              >
                <v-select
                  label="Name"
                  :placeholder="$t('content.select_country')"
                  v-model="additionalsToEdit.DischargeCountry"
                  :clearable="false"
                  :reduce="(country) => country.Alpha3"
                  :options="countriesList"
                >
                  <template #no-options="{}">
                    {{ $t("content.no_match") }}
                  </template>
                </v-select>
                <span class="small text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </b-form-group>
        </div>
        <div
          class="col-md-6"
          v-if="
            showField('DischargeCity') && !additionalQuestions.DischargeCity
          "
        >
          <b-form-group :label="$t('content.discharge_city')">
            <div class="content-area">
              <validation-provider
                :rules="
                  requiredField('DischargeCity')
                    ? 'required|alpha_spaces'
                    : 'alpha_spaces'
                "
                v-slot="{ errors }"
              >
                <input
                  v-model="additionalsToEdit.DischargeCity"
                  class="form-control"
                />
                <span class="small text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </b-form-group>
        </div>
        <div
          class="col-md-6"
          v-if="
            showField('DestinationCity') && !additionalQuestions.DestinationCity
          "
        >
          <b-form-group :label="$t('content.destination_city')">
            <div class="content-area">
              <validation-provider
                :rules="
                  requiredField('DestinationCity')
                    ? 'required|alpha_spaces'
                    : 'alpha_spaces'
                "
                v-slot="{ errors }"
              >
                <input
                  v-model="additionalsToEdit.DestinationCity"
                  class="form-control"
                />
                <span class="small text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </b-form-group>
        </div>
        <div
          class="col-md-6"
          v-if="
            showField('ReferenceNumber') && !additionalQuestions.ReferenceNumber
          "
        >
          <b-form-group :label="$t('content.reference_number')">
            <div class="content-area">
              <validation-provider
                :rules="requiredField('ReferenceNumber') ? 'required' : ''"
                v-slot="{ errors }"
              >
                <input
                  v-model="additionalsToEdit.ReferenceNumber"
                  class="form-control"
                />
                <span class="small text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </b-form-group>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { validate } from "vee-validate";
import moment from "moment-timezone";
export default {
  props: {
    additionalQuestions: {
      type: Object,
      required: true,
    },
    additionalsToEdit: {
      required: true,
    },
    inputsToEdit: {
      type: Object,
      required: true,
    },
    contract: {
      type: Object,
      required: true,
    },
    countryStates: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      startDateIsValid: false,
      endDateIsValid: false,
      startDateTimeStamp: null,
      endDateTimeStamp: null,
    };
  },
  async mounted() {
    await this.checkDates();
    this.checkCountries();
    this.$nextTick(() => {
      if (this.$refs.content.$children.length === 0) {
        this.$emit("is-empty");
      }
    });
  },
  computed: {
    countriesList() {
      return Object.values(this.countryStates.Countries);
    },
  },
  methods: {
    requiredField(prop) {
      return (
        this.contract.ContractData.Data.ContractAdditionalQuestions[prop] === 1
      );
    },
    showField(prop) {
      return (
        this.contract.ContractData.Data.ContractAdditionalQuestions[prop] ===
          1 ||
        (this.contract.ContractData.Data.ContractAdditionalQuestions[prop] ===
          2 &&
          this.contract.ContractData.Data.ContractSettings
            .ShowOptionalQuestions)
      );
    },
    async checkDates() {
      this.startDateIsValid = await this.dateIsValid(
        this.additionalsToEdit.ExpectedStartDate
      );
      if (!this.startDateIsValid) {
        this.additionalsToEdit.ExpectedStartDate = null;
      }
      this.endDateIsValid = await this.dateIsValid(
        this.additionalsToEdit.ExpectedEndDate
      );
      if (!this.endDateIsValid) {
        this.additionalsToEdit.ExpectedEndDate = null;
      }
    },
    checkCountries() {
      if (
        !this.countryStates.Countries[this.additionalsToEdit.DischargeCountry]
      ) {
        this.additionalsToEdit.DischargeCountry = null;
      }
      if (
        !this.countryStates.Countries[this.additionalsToEdit.LoadingCountry]
      ) {
        this.additionalsToEdit.LoadingCountry = null;
      }
    },
    showNamedPlaceOfIncoterm() {
      if (!this.inputsToEdit.Incoterm) {
        return false;
      }
      return (
        this.showField("NamedPlaceOfIncoterm") &&
        !this.additionalQuestions.IncotermNamedPlace
      );
    },
    async dateIsValid(val) {
      const result = await validate(val, "required|date");
      const dateIsSameOrAfter = moment(val).isSameOrAfter(
        moment().format("YYYY-MM-DD")
      );
      return result.valid && dateIsSameOrAfter;
    },
  },
};
</script>

<style lang="scss">
.imt-quote {
  .additional-information {
    @media screen and (min-width: 1250px) {
      padding-right: 110px;
      padding-left: 110px;
    }
  }
}
</style>
