import Vue from 'vue';
import VueLodash from 'vue-lodash';
import { isEmpty, sumBy, xorBy } from 'lodash';
import VueFormWizard from 'vue-form-wizard';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import vueCustomElement from 'vue-custom-element';
import 'document-register-element/build/document-register-element';
import './filters/index.js';
import money from 'v-money';
import vSelect from 'vue-select';
import ImtQuote from './App.vue';

// Configure Vue to ignore the element name when defined outside of Vue.
// Vue.config.ignoredElements = ["imt-quote"];

Vue.component('v-select', vSelect);
Vue.use(money, { precision: 2, decimal: '.', thousands: ' ', masked: false });
Vue.use(VueLodash, { lodash: { isEmpty, sumBy, xorBy } });
Vue.use(VueFormWizard);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// Enable the plugin
Vue.use(vueCustomElement);

// Register your component
Vue.customElement('imt-quote', ImtQuote, {
  // Additional Options: https://github.com/karol-f/vue-custom-element#options
});
