class GlobalConfig {
  ApiGeneral = process.env.VUE_APP_ApiGeneral;
  ApiCountry = process.env.VUE_APP_ApiCountry;
  ApiUsers = process.env.VUE_APP_ApiUsers;
  ApiSecurity = process.env.VUE_APP_ApiSecurity;
  ApiOrder = process.env.VUE_APP_ApiOrder;
  ApiWebjob = process.env.VUE_APP_ApiWebjob;
}

const globalConfig = new GlobalConfig();

export default globalConfig;
