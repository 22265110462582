<template>
  <div class="quote-configuration" v-if="inputsToEdit && result">
    <div class="wrapper insurance-types">
      <div class="row pb-4 m-0 justify-content-center">
        <div
          class="col-lg-4 p-0 clickable"
          v-if="
            insuranceTypes[0].Selected !== 4 &&
            result.quoteResult.QuotesByInsuranceType[1] != null
          "
        >
          <b-card
            @click="changeInsuranceType(1)"
            :class="
              inputsToEdit.InsuranceType === 1
                ? 'icc-card selected'
                : 'icc-card'
            "
            align="center"
          >
            <b-card-text>
              <div class="title">
                {{ $t("content.icc_a") }}<br />
                <span
                  class="price"
                  v-if="iccPremiums && insuranceTypes[0].Selected === 2"
                  >{{
                    iccPremiums[1].amount | formatMoney(iccPremiums[1].currency)
                  }}
                </span>
                <span class="price" v-else-if="insuranceTypes[0].Selected === 3"
                  >Referral</span
                >
              </div>
            </b-card-text>
          </b-card>
        </div>
        <div
          class="col-lg-4 p-0 clickable"
          v-if="
            insuranceTypes[1].Selected !== 4 &&
            result.quoteResult.QuotesByInsuranceType[2] != null
          "
        >
          <b-card
            @click="changeInsuranceType(2)"
            :class="
              inputsToEdit.InsuranceType === 2
                ? 'icc-card selected'
                : 'icc-card'
            "
            align="center"
          >
            <b-card-text>
              <div class="title">
                {{ $t("content.icc_b") }}<br />
                <span
                  class="price"
                  v-if="iccPremiums && insuranceTypes[1].Selected === 2"
                  >{{
                    iccPremiums[2].amount | formatMoney(iccPremiums[2].currency)
                  }}
                </span>
                <span class="price" v-else-if="insuranceTypes[1].Selected === 3"
                  >Referral</span
                >
              </div>
            </b-card-text>
          </b-card>
        </div>
        <div
          class="col-lg-4 p-0 clickable"
          v-if="
            insuranceTypes[2].Selected !== 4 &&
            result.quoteResult.QuotesByInsuranceType[3] != null
          "
        >
          <b-card
            @click="changeInsuranceType(3)"
            :class="
              inputsToEdit.InsuranceType === 3
                ? 'icc-card selected'
                : 'icc-card'
            "
            align="center"
          >
            <b-card-text>
              <div class="title">
                {{ $t("content.icc_c") }}<br />
                <span
                  class="price"
                  v-if="iccPremiums && insuranceTypes[2].Selected === 2"
                  >{{
                    iccPremiums[3].amount | formatMoney(iccPremiums[3].currency)
                  }}
                </span>
                <span class="price" v-else-if="insuranceTypes[2].Selected === 3"
                  >Referral</span
                >
              </div>
            </b-card-text>
          </b-card>
        </div>
      </div>
      <div class="seperator">
        {{ $t("content.included") }}
        <template v-if="inputsToEdit.InsuranceType === 1">
          <br />
          <span
            >"All Risks" of physical loss or damage to the goods by accidental
            causes during the ordinary course of transit.</span
          >
        </template>
      </div>
      <div class="row pt-2 pb-2 clauses">
        <div class="col-md-4">
          <div class="d-flex">
            <div><i class="fa fa-check-circle" aria-hidden="true"></i></div>
            <div>Fire or explosion</div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex">
            <div><i class="fa fa-check-circle" aria-hidden="true"></i></div>
            <div>Stranding, grounding, sinking or capesizing</div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex">
            <div><i class="fa fa-check-circle" aria-hidden="true"></i></div>
            <div>Overturning/derailment of the land conveyance</div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex">
            <div><i class="fa fa-check-circle" aria-hidden="true"></i></div>
            <div>Collision involving the carrying conveyance</div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex">
            <div><i class="fa fa-check-circle" aria-hidden="true"></i></div>
            <div>Discharge of cargo at a port of distress</div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex">
            <div><i class="fa fa-check-circle" aria-hidden="true"></i></div>
            <div>Jettison</div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex">
            <div><i class="fa fa-check-circle" aria-hidden="true"></i></div>
            <div>General Average</div>
          </div>
        </div>
        <template
          v-if="
            inputsToEdit.InsuranceType === 1 || inputsToEdit.InsuranceType === 2
          "
        >
          <div class="col-md-4">
            <div class="d-flex">
              <div><i class="fa fa-check-circle" aria-hidden="true"></i></div>
              <div>Earthquake, lightning and volcanic eruption</div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="d-flex">
              <div><i class="fa fa-check-circle" aria-hidden="true"></i></div>
              <div>Entry of sea, lake or river water</div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="d-flex">
              <div><i class="fa fa-check-circle" aria-hidden="true"></i></div>
              <div>Packages totally lost during loading or unloading</div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="d-flex">
              <div><i class="fa fa-check-circle" aria-hidden="true"></i></div>
              <div>Washing overboard</div>
            </div>
          </div>
        </template>
        <template v-if="inputsToEdit.InsuranceType === 1">
          <div class="col-md-4">
            <div class="d-flex">
              <div><i class="fa fa-check-circle" aria-hidden="true"></i></div>
              <div>Accidental damage</div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="d-flex">
              <div><i class="fa fa-check-circle" aria-hidden="true"></i></div>
              <div>Malicious damage theft/pilferage</div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="d-flex">
              <div><i class="fa fa-check-circle" aria-hidden="true"></i></div>
              <div>Contact with other cargo</div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="d-flex">
              <div><i class="fa fa-check-circle" aria-hidden="true"></i></div>
              <div>Hook, damage, mud, grease</div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="d-flex">
              <div><i class="fa fa-check-circle" aria-hidden="true"></i></div>
              <div>Improper stowage by shipowners</div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="d-flex">
              <div><i class="fa fa-check-circle" aria-hidden="true"></i></div>
              <div>Ship sweat, steam of hold</div>
            </div>
          </div>
        </template>
      </div>
      <template v-if="includedAdditionalClauses.length > 0">
        <div class="seperator">
          {{ $t("content.add_ons_incl") }}
        </div>
        <div class="row pt-2 pb-2 clauses">
          <div
            v-for="clause in includedAdditionalClauses"
            :key="clause.Id"
            class="col-md-4"
          >
            <div class="d-flex">
              <div><i class="fa fa-check-circle" aria-hidden="true"></i></div>
              <div>
                {{ clause.Name }}
                <template v-if="clause.Liability > 0">
                  <div class="liability">
                    {{ $t("content.max_liability") }}:
                    {{
                      clause.Liability
                        | formatMoney(state.tenantSettings.CurrencyAlpha3, 0)
                    }}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="additionalClauses.length > 0">
        <div class="seperator">
          {{ $t("content.add_ons") }}
        </div>
        <div class="row pt-2 pb-2 clauses">
          <div
            v-for="clause in additionalClauses"
            :key="clause.Id"
            class="col-md-4"
          >
            <b-form-checkbox
              @change="toggleClause(clause.Id)"
              :checked="
                inputsToEdit.AdditionalClauses.findIndex(
                  (c) => c === clause.Id
                ) !== -1
              "
            >
              {{ clause.Name }}
              <div class="liability" v-if="clause.Liability > 0">
                {{ $t("content.max_liability") }}:
                {{
                  clause.Liability
                    | formatMoney(state.tenantSettings.CurrencyAlpha3, 0)
                }}
              </div>
            </b-form-checkbox>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    inputsToEdit: {
      type: Object,
      required: true,
    },
    state: {
      type: Object,
      required: true,
    },
    result: {
      required: true,
    },
    contract: {
      type: Object,
      required: true,
    },
    countryStates: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      iccInformationExpanded: false,
    };
  },
  methods: {
    getQuestionByTransportMode(val) {
      return this.contract.ContractData.Data.ContractQuestions.find(
        (q) => q.OptionsApi === val
      ).Options.find(
        (o) => o.TransportModeId === this.inputsToEdit.TransportMode
      );
    },
    changeInsuranceType(val) {
      this.inputsToEdit.InsuranceType = val;
      this.state.selectedSelfrisk = 0;
      if (this.inputsToEdit.AdditionalClauses.length > 0) {
        this.inputsToEdit.AdditionalClauses = [];
        this.$emit("update-quote");
      }
    },
    toggleClause(val) {
      const index = this.inputsToEdit.AdditionalClauses.findIndex(
        (c) => c === val
      );
      if (index === -1) {
        this.inputsToEdit.AdditionalClauses.push(val);
      } else {
        this.inputsToEdit.AdditionalClauses.splice(index, 1);
      }
      this.$emit("update-quote");
    },
  },
  computed: {
    insuranceTypes() {
      return this.getQuestionByTransportMode("InsuranceType").Items;
    },
    icc() {
      let icc = "";
      switch (this.inputsToEdit.InsuranceType) {
        case 1:
          icc = "IccA";
          break;
        case 2:
          icc = "IccB";
          break;
        case 3:
          icc = "IccC";
          break;
      }
      return icc;
    },
    iccPremiums() {
      if (!this.result || !this.inputsToEdit) return null;

      const premiums = {};
      for (const insuranceType in this.result.quoteResult
        .QuotesByInsuranceType) {
        if (this.result.quoteResultInLocalCurrency) {
          if (
            this.result.quoteResultInLocalCurrency.QuotesByInsuranceType[
              insuranceType
            ].PremiumsBySelfrisk
          ) {
            const amount =
              this.result.quoteResultInLocalCurrency.QuotesByInsuranceType[
                insuranceType
              ].PremiumsBySelfrisk[0].PremiumToPay.toFixed(2);
            const currency = this.result.quoteResultInLocalCurrency.Currency;
            premiums[insuranceType] = { amount, currency };
          } else {
            const amount =
              this.result.quoteResultInLocalCurrency.QuotesByInsuranceType[
                insuranceType
              ].PremiumToPayNoSelfrisk.toFixed(2);
            const currency = this.result.quoteResultInLocalCurrency.Currency;
            premiums[insuranceType] = { amount, currency };
          }
        } else {
          if (
            this.result.quoteResult.QuotesByInsuranceType[insuranceType]
              .PremiumsBySelfrisk
          ) {
            const amount =
              this.result.quoteResult.QuotesByInsuranceType[
                insuranceType
              ].PremiumsBySelfrisk[0].PremiumToPay.toFixed(2);
            const currency = this.result.quoteResult.Currency;
            premiums[insuranceType] = { amount, currency };
          } else {
            const amount =
              this.result.quoteResult.QuotesByInsuranceType[
                insuranceType
              ].PremiumToPayNoSelfrisk.toFixed(2);
            const currency = this.result.quoteResult.Currency;
            premiums[insuranceType] = { amount, currency };
          }
        }
      }
      return premiums;
    },
    includedAdditionalClauses() {
      const questionByTransportMode =
        this.getQuestionByTransportMode("ExtraClauses");
      const clauses = questionByTransportMode.Items.filter(
        (x) => x.Enabled === true && x[this.icc] === true
      );
      return clauses;
    },
    additionalClauses() {
      const questionByTransportMode =
        this.getQuestionByTransportMode("GroupExtraClauses");
      if (questionByTransportMode.CustomizationMode === 1) {
        return [];
      }
      const clauses = questionByTransportMode.Items.filter(
        (x) => x.Enabled === true && x[this.icc] === true
      );
      return this._.xorBy(clauses, this.includedAdditionalClauses, "Id");
    },
  },
};
</script>
<style lang="scss">
.imt-quote {
  .card.icc-card {
    border: 1px solid #95989a;
    border-radius: 14px;
    font-size: 16px;
    letter-spacing: 0px;
    height: 70px;
    margin: 2px;
    .card-body {
      padding: 0.25em;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title {
      color: #231f20;
      .price {
        font-weight: bold;
      }
    }
    .price {
      font-weight: bolder;
      color: #231f20;
    }
    &.selected {
      border: 4px solid #ff772d;
    }
  }
  .quote-configuration {
    @media screen and (min-width: 1250px) {
      padding-right: 110px;
      padding-left: 110px;
    }
    .seperator {
      min-height: 30px;
      background-color: #f5f5f5;
      font-weight: bold;
      padding: 0 20px;
      padding-top: 2px;
      span {
        font-weight: normal;
      }
    }
    .clauses {
      > div {
        padding-bottom: 0.25rem !important;
      }
      font-size: 14px;
      i {
        color: #3bb692;
        padding-right: 7px;
      }
      .liability {
        font-size: 9px;
        letter-spacing: 0px;
        color: #9a9b9d;
      }
    }
    .insurance-types {
      .table .thead-custom th {
        border: 0;
        text-align: center;
      }

      .bg-orange {
        background-color: #f68b3e !important;
      }

      .center .table td {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
      }

      .header-text-blue {
        color: #36b9cc;
        text-align: center;
        font-size: 20px;
      }
      .header-text-disabled {
        color: #7d7d7d;
        text-align: center;
        font-size: 20px;
      }
      .header-sub-text {
        color: #858796;
        font-size: 14px;
      }

      .header-text-orange {
        color: #f68b3e;
        text-align: center;
        font-size: 20px;
      }

      .header-text-green {
        color: #1cc88a;
        text-align: center;
        font-size: 20px;
      }

      .header-sub {
        font-size: 16px;
        font-weight: 600;
        text-align: right !important;
        vertical-align: middle;
      }

      .table td,
      .table th {
        text-align: center;
      }

      .table-striped tbody tr:nth-of-type(odd) {
        background-color: #fff;
      }

      .fa-color-green {
        color: #1cc88a;
      }

      .fa-color-red {
        color: #e1756b;
      }

      .form-check-table {
        height: 20px;
        position: relative;
        display: block;
        padding-left: 1.75rem;
      }
    }
  }
}
</style>
