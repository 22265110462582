import Vue from "vue";
import { i18n } from "./i18n";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
  setInteractionMode
} from "vee-validate";
import {
  required,
  email,
  between,
  integer,
  regex,
  alpha_spaces,
  min_value
} from "vee-validate/dist/rules";

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    // values._field_ = i18n.t(`fields.${field}`);
    return i18n.t(`validation.${values._rule_}`, values);
  }
});

setInteractionMode("lazy");
extend("required", required);
extend("email", email);
extend("phone_regex", regex);
extend("alpha_spaces", alpha_spaces);
extend("between", between);
extend("oddsized_items_match", between);
extend("integer", integer);
extend("min_value", min_value);
extend("date", {
  validate: value =>
    /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/.test(value)
});
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
