const currencyToLocale = {
  GBP: "en-UK",
  USD: "en-US",
  SEK: "sv-SE",
  EUR: "de-DE"
};
import Vue from "vue";
Vue.filter("formatMoney", (value, currency, decimals) => {
  if (!currency) {
    return value;
  }
  const lang = currencyToLocale[currency] ?? "sv-SE";
  return new Intl.NumberFormat(lang, {
    style: "currency",
    currency: currency,
    maximumFractionDigits: decimals ?? 2,
    minimumFractionDigits: decimals ?? 2
  }).format(value);
});
