var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"deductible"},[(
      _vm.result &&
        _vm.result.quoteResult.QuotesByInsuranceType[
          _vm.inputsToEdit.InsuranceType
        ] &&
        _vm.result.quoteResult.QuotesByInsuranceType[_vm.inputsToEdit.InsuranceType]
          .PremiumsBySelfrisk
    )?_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"row pb-5 m-0 justify-content-center"},_vm._l((_vm.result.quoteResult.QuotesByInsuranceType[
          _vm.inputsToEdit.InsuranceType
        ].PremiumsBySelfrisk),function(option,index){return _c('div',{key:option.FreightServiceId + '-' + index,staticClass:"col-lg-4 p-0 clickable"},[_c('b-card',{class:_vm.state.selectedSelfrisk === index
              ? 'selfrisk-card selected'
              : 'selfrisk-card',attrs:{"align":"center"},on:{"click":function($event){_vm.state.selectedSelfrisk = index}}},[_c('b-card-text',[(_vm.result.quoteResultInLocalCurrency)?[_c('span',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("content.deductible_amount"))+": "+_vm._s(_vm._f("formatMoney")((option.Selfrisk * _vm.result.quoteResultInLocalCurrency.CurrencyRate),_vm.result.quoteResultInLocalCurrency.Currency)))]),(option.PremiumToPay)?_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("content.price_after_discount"))+": "+_vm._s(_vm._f("formatMoney")((option.PremiumToPay * _vm.result.quoteResultInLocalCurrency.CurrencyRate),_vm.result.quoteResultInLocalCurrency.Currency))+" ")]):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("content.no_quote"))+" ")])]:[_c('span',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("content.deductible_amount"))+": "+_vm._s(_vm._f("formatMoney")(option.Selfrisk,_vm.result.quoteResult.Currency)))]),(option.PremiumToPay)?_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("content.price_after_discount"))+": "+_vm._s(_vm._f("formatMoney")(option.PremiumToPay,_vm.result.quoteResult.Currency))+" ")]):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("content.no_quote"))+". ")])]],2)],1)],1)}),0)]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("content.nodeductibles"))+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }