<template>
  <div
    v-if="state.paymentModal && contract"
    class="custom-modal secondary-modal"
  >
    <div class="row justify-content-center">
      <div class="col-xl-6">
        <div class="custom-modal-content">
          <div class="custom-modal-header bg-imt-quote">
            <h5 class="mb-0 text-light">Process payment</h5>
            <span
              @click="closePaymentModal()"
              class="close white-close clickable"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </span>
          </div>
          <div class="custom-modal-body">
            <div class="p-4">
              <p>
                <strong>Choose payment method</strong>
              </p>
              <div class="row m-0 justify-content-center">
                <div class="col-4 p-0 clickable" v-if="showCard">
                  <b-card
                    @click="
                      !orderSucceeded ? $emit('setPaymentMethod', 1) : null
                    "
                    :class="
                      paymentMethod === 1
                        ? 'payment-method-card selected'
                        : 'payment-method-card'
                    "
                    align="center"
                  >
                    <b-card-text>
                      <div>
                        <i class="fa fa-credit-card" aria-hidden="true"></i
                        >&nbsp;
                        <strong>Card</strong>
                      </div>
                    </b-card-text>
                  </b-card>
                </div>
                <div class="col-4 p-0 clickable" v-if="showIdeal">
                  <b-card
                    @click="
                      !orderSucceeded ? $emit('setPaymentMethod', 3) : null
                    "
                    :class="
                      paymentMethod === 3
                        ? 'payment-method-card selected'
                        : 'payment-method-card'
                    "
                    align="center"
                  >
                    <b-card-text>
                      <div>
                        <i class="fa fa-university" aria-hidden="true"></i
                        >&nbsp;
                        <strong>iDEAL</strong>
                      </div></b-card-text
                    >
                  </b-card>
                </div>
                <div class="col-4 p-0 clickable" v-if="showBancontact">
                  <b-card
                    @click="
                      !orderSucceeded ? $emit('setPaymentMethod', 4) : null
                    "
                    :class="
                      paymentMethod === 4
                        ? 'payment-method-card selected'
                        : 'payment-method-card'
                    "
                    align="center"
                  >
                    <b-card-text>
                      <div>
                        <i class="fa fa-university" aria-hidden="true"></i
                        >&nbsp;
                        <strong>Bancontact</strong>
                      </div></b-card-text
                    >
                  </b-card>
                </div>
                <div class="col-4 p-0 clickable" v-if="showGiropay">
                  <b-card
                    @click="
                      !orderSucceeded ? $emit('setPaymentMethod', 5) : null
                    "
                    :class="
                      paymentMethod === 5
                        ? 'payment-method-card selected'
                        : 'payment-method-card'
                    "
                    align="center"
                  >
                    <b-card-text>
                      <div>
                        <i class="fa fa-university" aria-hidden="true"></i
                        >&nbsp;
                        <strong>Giropay</strong>
                      </div></b-card-text
                    >
                  </b-card>
                </div>
                <div class="col-4 p-0 clickable" v-if="showEps">
                  <b-card
                    @click="
                      !orderSucceeded ? $emit('setPaymentMethod', 6) : null
                    "
                    :class="
                      paymentMethod === 6
                        ? 'payment-method-card selected'
                        : 'payment-method-card'
                    "
                    align="center"
                  >
                    <b-card-text>
                      <div>
                        <i class="fa fa-university" aria-hidden="true"></i
                        >&nbsp;
                        <strong>Eps</strong>
                      </div></b-card-text
                    >
                  </b-card>
                </div>
                <div class="col-4 p-0 clickable" v-if="showInvoice">
                  <b-card
                    @click="
                      !orderSucceeded ? $emit('setPaymentMethod', 2) : null
                    "
                    :class="
                      paymentMethod === 2
                        ? 'payment-method-card selected'
                        : 'payment-method-card'
                    "
                    align="center"
                  >
                    <b-card-text>
                      <div>
                        <i class="fa fa-file-text-o" aria-hidden="true"></i
                        >&nbsp;
                        <strong>Invoice</strong>
                      </div></b-card-text
                    >
                  </b-card>
                </div>
              </div>
            </div>
            <div class="p-4 pt-0" v-show="paymentMethod === 1">
              <form class="stripe" id="payment-form">
                <b-form-group class="stripe-card-group">
                  <b-form-radio
                    v-model="state.selectedStripeCard"
                    name="stripe-card"
                    v-for="method in state.stripeCardPaymentMethods"
                    :key="method.id"
                    :value="method.id"
                  >
                    <div class="stripe-card-row">
                      <i
                        v-if="method.card.brand === 'visa'"
                        class="fa fa-cc-visa"
                        aria-hidden="true"
                      ></i>
                      <i
                        v-else-if="method.card.brand === 'mastercard'"
                        class="fa-cc-mastercard"
                        aria-hidden="true"
                      ></i>
                      <i
                        v-else-if="method.card.brand === 'diners'"
                        class="fa fa-cc-diners-club"
                        aria-hidden="true"
                      ></i>
                      <i
                        v-else-if="method.card.brand === 'discover'"
                        class="fa fa-cc-discover"
                        aria-hidden="true"
                      ></i>
                      <i
                        v-else
                        class="fa fa-credit-card"
                        aria-hidden="true"
                      ></i>
                      **** **** **** {{ method.card.last4 }}<br />
                      <span class="text-small"
                        >Expires {{ method.card.exp_year }}/{{
                          method.card.exp_month
                        }}</span
                      >
                    </div>
                  </b-form-radio>
                  <b-form-radio
                    v-model="state.selectedStripeCard"
                    value="new"
                    name="stripe-card"
                  >
                    <div id="card-element"></div>
                  </b-form-radio>
                </b-form-group>
                <button
                  v-if="paymentIntentReady('card')"
                  :disabled="
                    orderSucceeded || loadingPayment || stripeValidationError
                  "
                >
                  <div v-if="loadingPayment" class="spinner" id="spinner"></div>
                  <span v-else id="button-text">Pay and bind cover</span>
                </button>
                <p
                  v-show="stripeValidationError"
                  id="card-error"
                  role="alert"
                ></p>
              </form>
            </div>
            <div class="p-4 pt-0" v-show="paymentMethod === 3">
              <div class="stripe">
                <div class="ideal">
                  <div id="ideal-bank-element"></div>
                </div>
                <button
                  v-if="paymentIntentReady('ideal')"
                  @click="payWithIdeal"
                  :disabled="orderSucceeded"
                >
                  <div v-if="loadingPayment" class="spinner" id="spinner"></div>
                  <span v-else id="button-text">Pay and bind cover</span>
                </button>
              </div>
            </div>
            <div class="p-4 pt-0" v-show="paymentMethod === 4">
              <div class="stripe">
                <div class="bancontact">
                  <input
                    placeholder="Bank Account Holder"
                    :disabled="orderSucceeded"
                    v-model="accountHolderName"
                    class="form-control"
                  />
                </div>
                <button
                  v-if="paymentIntentReady('bancontact')"
                  @click="payWithBancontact"
                  :disabled="orderSucceeded"
                >
                  <div v-if="loadingPayment" class="spinner" id="spinner"></div>
                  <span v-else id="button-text">Pay and bind cover</span>
                </button>
              </div>
            </div>
            <div class="p-4 pt-0" v-show="paymentMethod === 5">
              <div class="stripe">
                <div class="giropay">
                  <input
                    placeholder="Bank Account Holder"
                    :disabled="orderSucceeded"
                    v-model="accountHolderName"
                    class="form-control"
                  />
                </div>
                <button
                  v-if="paymentIntentReady('giropay')"
                  @click="payWithGiropay"
                  :disabled="orderSucceeded"
                >
                  <div v-if="loadingPayment" class="spinner" id="spinner"></div>
                  <span v-else id="button-text">Pay and bind cover</span>
                </button>
              </div>
            </div>
            <div class="p-4 pt-0" v-show="paymentMethod === 6">
              <div class="stripe">
                <div class="eps">
                  <input
                    placeholder="Bank Account Holder"
                    :disabled="orderSucceeded"
                    v-model="accountHolderName"
                    class="form-control"
                  />
                  <div id="eps-bank-element"></div>
                </div>
                <button
                  v-if="paymentIntentReady('eps')"
                  @click="payWithEps"
                  :disabled="orderSucceeded"
                >
                  <div v-if="loadingPayment" class="spinner" id="spinner"></div>
                  <span v-else id="button-text">Pay and bind cover</span>
                </button>
              </div>
            </div>
            <div class="p-4 pt-0" v-if="paymentMethod === 2">
              <div class="stripe">
                <button @click="payWithInvoice" :disabled="orderSucceeded">
                  <div v-if="loadingPayment" class="spinner" id="spinner"></div>
                  <span v-else id="button-text">Bind cover</span>
                </button>
              </div>
            </div>
            <div>
              <p v-if="orderSucceeded" class="result-message">
                Thank you for your order.
                <span
                  v-if="
                    contract.ContractData.Data.ContractSettings
                      .SendOrderConfirmation
                  "
                  >You will receive an order confirmation via email (please
                  check spam) shortly!</span
                >
              </p>
              <p
                v-if="
                  orderSucceeded &&
                  !!bank_url &&
                  paymentMethod !== 1 &&
                  paymentMethod !== 2
                "
                class="result-message"
              >
                Proceed to the payment by opening
                <a :href="bank_url" target="_blank">this page</a>.
              </p>
              <p
                v-if="
                  orderSucceeded && paymentMethod !== 1 && paymentMethod !== 2
                "
                class="try-again"
              >
                In case of errors
                <a
                  href="javascript:void(0)"
                  @click="$emit('setOrderSucceeded', false)"
                  >try again</a
                >
                with another payment method.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import globalConfig from "@/global.config.js";
import axios from "axios";
export default {
  props: {
    contract: {
      type: Object,
      required: true,
    },
    state: {
      type: Object,
      required: true,
    },
    stripe: {
      required: true,
    },
    premiumToPay: {
      required: true,
    },
    tenantId: {
      type: String,
      required: true,
    },
    distributionLineId: {
      type: Number,
      required: true,
    },
    userEmail: {
      type: String,
      required: true,
    },
    externalCustomerId: {
      type: String,
      required: true,
    },
    externalOrderId: {
      type: String,
      required: true,
    },
    orderSucceeded: {
      type: Boolean,
      required: true,
    },
    paymentMethod: {
      required: true,
    },
  },
  data() {
    return {
      country: null,
      loadingPayment: false,
      stripeValidationError: false,
      apiWebJob: null,
      apiOrder: null,
      orderCreated: null,
      bank_url: null,
      return_url: "https://insuremytrans.tech/checkout-complete",
      accountHolderName:
        this.state.orderPayload.CustomerDetails.Firstname +
        " " +
        this.state.orderPayload.CustomerDetails.Lastname,
      methodsMapping: {
        1: "card",
        2: "invoice",
        3: "ideal",
        4: "bancontact",
        5: "giropay",
        6: "eps",
      },
    };
  },
  async mounted() {
    this.country =
      this.state.orderPayload.QuoteInput.PurchasedByThirdPartyCountry ??
      this.state.orderPayload.QuoteInput.Country;
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.state.token;
    this.apiOrder = axios.create({
      baseURL: globalConfig.ApiOrder,
    });
    this.apiWebjob = axios.create({
      baseURL: globalConfig.ApiWebjob,
    });
    if (this.contract.ContractData.Data.ContractSettings.PaymentMethod !== 3) {
      await this.initializePayment();
    } else {
      // Preselect invoice if invoice is the only payment method
      this.$emit("setPaymentMethod", 2);
    }
  },
  computed: {
    paymentIntentReady() {
      return (paymentMethodType) =>
        !!this.state.stripePaymentSecrets[paymentMethodType];
    },
    showEps() {
      return (
        this.contract.ContractData.Data.ContractSettings.PaymentMethodTypes.find(
          (method) => method === "eps"
        ) &&
        this.country === "AUT" &&
        this.state.orderPayload.QuoteInput.Currency === "EUR"
      );
    },
    showGiropay() {
      return (
        this.contract.ContractData.Data.ContractSettings.PaymentMethodTypes.find(
          (method) => method === "giropay"
        ) &&
        this.country === "DEU" &&
        this.state.orderPayload.QuoteInput.Currency === "EUR"
      );
    },
    showIdeal() {
      return (
        this.contract.ContractData.Data.ContractSettings.PaymentMethodTypes.find(
          (method) => method === "ideal"
        ) &&
        this.country === "NLD" &&
        this.state.orderPayload.QuoteInput.Currency === "EUR"
      );
    },
    showBancontact() {
      return this.contract.ContractData.Data.ContractSettings.PaymentMethodTypes.find(
        (method) =>
          method === "bancontact" &&
          this.country === "BEL" &&
          this.state.orderPayload.QuoteInput.Currency === "EUR"
      );
    },
    showCard() {
      return this.contract.ContractData.Data.ContractSettings.PaymentMethodTypes.find(
        (method) =>
          method === "card" &&
          this.contract.ContractData.Data.ContractSettings.PaymentMethod !== 3
      );
    },
    showInvoice() {
      return (
        this.contract.ContractData.Data.ContractSettings.PaymentMethod !== 2 &&
        !this.orderCreated
      );
    },
  },
  methods: {
    handleError(error) {
      if (error.response) {
        if (error.response.status === 401) {
          alert("Couldn't authorize your request. Refresh page and try again.");
        } else {
          alert(error.response.data.Message);
        }
      } else {
        alert("Error: " + error.message);
      }
    },
    async initializePayment() {
      const paymentCollector =
        this.contract.ContractData.Data.ContractPartners.find(
          (partner) =>
            partner.Id ===
            this.contract.ContractData.Data.ContractSettings.PaymentCollector
        );
      const paymentSecretPayload = {
        ...this.premiumToPay,
        TenantId: this.tenantId,
        TenantCompanyName: this.state.userClientRelations.Insurer.CompanyName,
        DistributionLineId: this.distributionLineId,
        StripeAccountId: paymentCollector.CompanyDetails.StripeAccountId,
        StripeCustomerId: this.state.stripeCustomerId,
        StripePaymentMethodId: this.state.stripePaymentMethodId,
        StripePaymentMethodType: null,
        UserEmail: this.userEmail,
        ExternalOrderId: this.externalOrderId,
        ExternalCustomerId: this.externalCustomerId,
      };
      if (paymentSecretPayload.StripeCustomerId) {
        await this.apiOrder
          .get(
            "payment/card-payment-methods/" +
              paymentSecretPayload.StripeAccountId +
              "/" +
              paymentSecretPayload.StripeCustomerId
          )
          .then((result) => {
            this.state.stripeCardPaymentMethods = result.data;
            const exists = result.data.find((method) => {
              return method.id === paymentSecretPayload.StripePaymentMethodId;
            });
            if (exists) {
              this.state.selectedStripeCard =
                paymentSecretPayload.StripePaymentMethodId;
            }
          });
      }

      const elements = this.stripe.elements();
      this.state.paymentModal = true;
      if (this.showCard) {
        this.initializeCard(elements);
      }
      if (this.showIdeal) {
        this.initializeIdeal(elements);
      }
      if (this.showEps) {
        this.initializeEps(elements);
      }
      paymentSecretPayload.StripeCustomerId = await this.fetchStripeCustomer(
        paymentSecretPayload
      );
      this.fetchPaymentSecrets(paymentSecretPayload);
    },
    async fetchStripeCustomer(paymentSecretPayload) {
      return await this.apiOrder
        .post("payment/stripe-customer", paymentSecretPayload)
        .then((result) => {
          this.state.orderPayload.StripeCustomerId = result.data;
          return result.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    async fetchPaymentSecrets(paymentSecretPayload) {
      if (
        this.contract.ContractData.Data.ContractSettings.PaymentMethodTypes
          .length === 0
      ) {
        return;
      }

      this.contract.ContractData.Data.ContractSettings.PaymentMethodTypes.forEach(
        async (method) => {
          if (method === "card" && !this.showCard) {
            return;
          }
          if (method === "eps" && !this.showEps) {
            return;
          }
          if (method === "ideal" && !this.showIdeal) {
            return;
          }
          if (method === "giropay" && !this.showGiropay) {
            return;
          }
          if (method === "bancontact" && !this.showBancontact) {
            return;
          }
          await this.apiOrder
            .post("payment/secret", {
              ...paymentSecretPayload,
              StripePaymentMethodType: method,
            })
            .then((result) => {
              const secret = result.data;
              this.$set(this.state.stripePaymentSecrets, method, secret);
            })
            .catch((error) => {
              this.handleError(error);
            });
        }
      );
    },
    initializeCard(elements) {
      const style = {
        base: {
          color: "#32325d",
          fontFamily: "Arial, sans-serif",
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#32325d",
          },
        },
        invalid: {
          fontFamily: "Arial, sans-serif",
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      };
      const card = elements.create("card", { style: style });
      this.$nextTick(() => {
        // Stripe injects an iframe into the DOM
        card.mount("#card-element");
        card.on("change", (event) => {
          // Disable the Pay button if there are no card details in the Element
          if (event.error) {
            this.stripeValidationError = true;
            document.querySelector("#card-error").textContent =
              event.error.message;
          } else {
            this.stripeValidationError = false;
          }
        });
        const form = document.getElementById("payment-form");
        form.addEventListener("submit", (event) => {
          event.preventDefault();
          // Complete payment when the submit button is clicked
          this.payWithCard(card);
        });
        card.on("focus", () => {
          this.state.selectedStripeCard = "new";
        });
      });
    },
    initializeIdeal(elements) {
      var options = {
        // Custom styling can be passed to options when creating an Element
        style: {
          base: {
            padding: "10px 12px",
            color: "#32325d",
            fontSize: "16px",
            "::placeholder": {
              color: "#aab7c4",
            },
          },
        },
      };
      // Create an instance of the idealBank Element
      const idealBank = elements.create("idealBank", options);
      this.$nextTick(() => {
        idealBank.mount("#ideal-bank-element");
      });
    },
    initializeEps(elements) {
      var options = {
        // Custom styling can be passed to options when creating an Element
        style: {
          base: {
            padding: "10px 12px",
            color: "#32325d",
            fontSize: "16px",
            "::placeholder": {
              color: "#aab7c4",
            },
          },
        },
      };
      // Create an instance of the epsBank Element
      const epsBank = elements.create("epsBank", options);
      this.$nextTick(() => {
        epsBank.mount("#eps-bank-element");
      });
    },
    payWithCard(card) {
      this.loadingPayment = true;
      this.stripe
        .confirmCardPayment(
          this.state.stripePaymentSecrets["card"].clientSecret,
          {
            payment_method:
              this.state.selectedStripeCard === "new"
                ? {
                    card: card,
                  }
                : this.state.selectedStripeCard,
            receipt_email: this.userEmail,
            setup_future_usage: "on_session",
          }
        )
        .then((result) => {
          if (result.error) {
            // Show error to your customer
            this.showError(result.error.message);
          } else {
            // The payment succeeded!
            if (this.orderCreated) {
              // order already exist so update order
              this.updateOrder(result.paymentIntent.id, true)
                .then(() => {
                  this.emitOrderSucceeded();
                })
                .catch((error) => {
                  this.handleError(error);
                })
                .finally(() => {
                  this.loadingPayment = false;
                });
            } else {
              // order doesn't exist
              this.state.orderPayload.PaymentId = result.paymentIntent.id;
              this.state.orderPayload.StripePaymentMethodId =
                result.paymentIntent.payment_method;
              this.state.orderPayload.IsPaid = true;
              this.orderComplete()
                .then(() => {
                  this.emitOrderSucceeded();
                })
                .catch((error) => {
                  this.handleError(error);
                })
                .finally(() => {
                  this.loadingPayment = false;
                });
            }
          }
        });
    },
    async payWithIdeal() {
      this.state.orderPayload.PaymentId =
        this.state.stripePaymentSecrets["ideal"].paymentIntentId;

      const idealBank = document.getElementById("ideal-bank-element");
      const classes = idealBank.classList;
      if (classes.contains("StripeElement--empty")) {
        return;
      }

      this.loadingPayment = true;

      if (this.orderCreated) {
        await this.confirmIdealPayment(idealBank);
        this.updateOrder(this.state.orderPayload.PaymentId)
          .then(() => {
            this.emitOrderSucceeded();
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.$nextTick(() => {
              this.loadingPayment = false;
            });
          });
        return;
      }

      this.orderCompleteInstant()
        .then(async (result) => {
          this.orderCreated = result.data;
          await this.confirmIdealPayment(idealBank);
          this.emitOrderSucceeded();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.$nextTick(() => {
            this.loadingPayment = false;
          });
        });
    },
    async confirmIdealPayment(idealBank) {
      delete idealBank["__zone_symbol__clickfalse"];
      await this.stripe
        .confirmIdealPayment(
          this.state.stripePaymentSecrets["ideal"].clientSecret,
          {
            payment_method: {
              ideal: idealBank,
            },
            return_url: this.return_url,
          },
          {
            handleActions: false,
          }
        )
        .then((result) => {
          if (result.error) {
            // Show error to your customer
            this.showError(result.error.message);
          } else {
            this.bank_url =
              result.paymentIntent.next_action.redirect_to_url.url;
          }
        });
    },
    async payWithBancontact() {
      this.state.orderPayload.PaymentId =
        this.state.stripePaymentSecrets["bancontact"].paymentIntentId;

      if (!this.accountHolderName) {
        return;
      }

      this.loadingPayment = true;

      if (this.orderCreated) {
        await this.confirmBancontactPayment();
        this.updateOrder(this.state.orderPayload.PaymentId)
          .then(() => {
            this.emitOrderSucceeded();
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.$nextTick(() => {
              this.loadingPayment = false;
            });
          });
        return;
      }

      this.orderCompleteInstant()
        .then(async (result) => {
          this.orderCreated = result.data;
          await this.confirmBancontactPayment();
          this.emitOrderSucceeded();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.$nextTick(() => {
            this.loadingPayment = false;
          });
        });
    },
    async confirmBancontactPayment() {
      await this.stripe
        .confirmBancontactPayment(
          this.state.stripePaymentSecrets["bancontact"].clientSecret,
          {
            payment_method: {
              billing_details: {
                name: this.accountHolderName,
              },
            },
            return_url: this.return_url,
          },
          {
            handleActions: false,
          }
        )
        .then((result) => {
          if (result.error) {
            // Show error to your customer
            this.showError(result.error.message);
          } else {
            this.bank_url =
              result.paymentIntent.next_action.redirect_to_url.url;
          }
        });
    },
    async payWithGiropay() {
      this.state.orderPayload.PaymentId =
        this.state.stripePaymentSecrets["giropay"].paymentIntentId;

      if (!this.accountHolderName) {
        return;
      }

      this.loadingPayment = true;

      if (this.orderCreated) {
        await this.confirmGiropayPayment();
        this.updateOrder(this.state.orderPayload.PaymentId)
          .then(() => {
            this.emitOrderSucceeded();
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.$nextTick(() => {
              this.loadingPayment = false;
            });
          });
        return;
      }

      this.orderCompleteInstant()
        .then(async (result) => {
          this.orderCreated = result.data;
          await this.confirmGiropayPayment();
          this.emitOrderSucceeded();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.$nextTick(() => {
            this.loadingPayment = false;
          });
        });
    },
    async confirmGiropayPayment() {
      await this.stripe
        .confirmGiropayPayment(
          this.state.stripePaymentSecrets["giropay"].clientSecret,
          {
            payment_method: {
              billing_details: {
                name: this.accountHolderName,
              },
            },
            return_url: this.return_url,
          },
          {
            handleActions: false,
          }
        )
        .then((result) => {
          if (result.error) {
            // Show error to your customer
            this.showError(result.error.message);
          } else {
            this.bank_url =
              result.paymentIntent.next_action.redirect_to_url.url;
          }
        });
    },
    async payWithEps() {
      this.state.orderPayload.PaymentId =
        this.state.stripePaymentSecrets["eps"].paymentIntentId;

      const epsBank = document.getElementById("eps-bank-element");
      const classes = epsBank.classList;
      if (classes.contains("StripeElement--empty") || !this.accountHolderName) {
        return;
      }

      this.loadingPayment = true;

      if (this.orderCreated) {
        await this.confirmEpsPayment(epsBank);
        this.updateOrder(this.state.orderPayload.PaymentId)
          .then(() => {
            this.emitOrderSucceeded();
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.$nextTick(() => {
              this.loadingPayment = false;
            });
          });
        return;
      }

      this.orderCompleteInstant()
        .then(async (result) => {
          this.orderCreated = result.data;
          await this.confirmEpsPayment(epsBank);
          this.emitOrderSucceeded();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.$nextTick(() => {
            this.loadingPayment = false;
          });
        });
    },
    async confirmEpsPayment(epsBank) {
      delete epsBank["__zone_symbol__clickfalse"];
      await this.stripe
        .confirmEpsPayment(
          this.state.stripePaymentSecrets["eps"].clientSecret,
          {
            payment_method: {
              eps: epsBank,
              billing_details: {
                name: this.accountHolderName,
              },
            },
            return_url: this.return_url,
          },
          {
            handleActions: false,
          }
        )
        .then((result) => {
          if (result.error) {
            // Show error to your customer
            this.showError(result.error.message);
          } else {
            this.bank_url =
              result.paymentIntent.next_action.redirect_to_url.url;
          }
        });
    },
    payWithInvoice() {
      this.loadingPayment = true;
      if (this.orderCreated) {
        this.emitOrderSucceeded();
        this.$nextTick(() => {
          this.loadingPayment = false;
        });
        return;
      }
      this.orderCompleteInstant()
        .then(() => {
          this.emitOrderSucceeded();
          setTimeout(() => {
            this.closePaymentModal();
          }, 10000);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.$nextTick(() => {
            this.loadingPayment = false;
          });
        });
    },
    orderComplete() {
      this.state.orderPayload.PaymentMethod = this.paymentMethod;
      return this.apiOrder.post("order", this.state.orderPayload);
    },
    orderCompleteInstant() {
      this.state.orderPayload.PaymentMethod = this.paymentMethod;
      return this.apiWebjob.post("order/instantly", this.state.orderPayload);
    },
    updateOrder(paymentIntentId, markAsPaid) {
      this.orderCreated.PaymentId = paymentIntentId;
      this.orderCreated.PaymentMethod = this.paymentMethod;
      return this.apiOrder.put(
        "order/external-update/" + (markAsPaid ?? false),
        this.orderCreated
      );
    },
    showError(errorMsgText) {
      this.loadingPayment = false;
      this.stripeValidationError = true;
      const errorMsg = document.querySelector("#card-error");
      errorMsg.textContent = errorMsgText;
      setTimeout(() => {
        errorMsg.textContent = "";
        this.stripeValidationError = false;
      }, 4000);
    },
    closePaymentModal() {
      this.state.paymentModal = false;
      this.$emit("setPaymentMethod", null);
    },
    emitOrderSucceeded() {
      this.$emit("setOrderSucceeded", true);
      this.$emit("order-succeeded", {
        premiumToPay: this.premiumToPay,
        paymentMethod: this.methodsMapping[this.paymentMethod],
        paymentUrl: this.bank_url,
      });
    },
  },
};
</script>
<style lang="scss">
.custom-modal {
  .result-message {
    line-height: 22px;
    font-size: 16px;
    text-align: center;
  }
  .try-again {
    text-align: center;
    font-size: 12px;
  }
  .ideal,
  .eps {
    margin-bottom: 30px;
    @media (min-width: 768px) {
      margin-left: 5.5rem;
      margin-right: 5.5rem;
    }
    input {
      text-align: center;
    }
  }
  .bancontact,
  .giropay {
    margin-bottom: 30px;
    @media (min-width: 768px) {
      margin-left: 5.5rem;
      margin-right: 5.5rem;
    }
    input {
      text-align: center;
    }
  }
}
</style>