<template>
  <div class="deductible">
    <div
      class="wrapper"
      v-if="
        result &&
          result.quoteResult.QuotesByInsuranceType[
            inputsToEdit.InsuranceType
          ] &&
          result.quoteResult.QuotesByInsuranceType[inputsToEdit.InsuranceType]
            .PremiumsBySelfrisk
      "
    >
      <div class="row pb-5 m-0 justify-content-center">
        <div
          class="col-lg-4 p-0 clickable"
          v-for="(option, index) in result.quoteResult.QuotesByInsuranceType[
            inputsToEdit.InsuranceType
          ].PremiumsBySelfrisk"
          :key="option.FreightServiceId + '-' + index"
        >
          <b-card
            @click="state.selectedSelfrisk = index"
            :class="
              state.selectedSelfrisk === index
                ? 'selfrisk-card selected'
                : 'selfrisk-card'
            "
            align="center"
          >
            <b-card-text>
              <template v-if="result.quoteResultInLocalCurrency">
                <span class="small"
                  >{{ $t("content.deductible_amount") }}:
                  {{
                    (option.Selfrisk *
                      result.quoteResultInLocalCurrency.CurrencyRate)
                      | formatMoney(result.quoteResultInLocalCurrency.Currency)
                  }}</span
                >
                <div v-if="option.PremiumToPay" class="text-center">
                  {{ $t("content.price_after_discount") }}:
                  {{
                    (option.PremiumToPay *
                      result.quoteResultInLocalCurrency.CurrencyRate)
                      | formatMoney(result.quoteResultInLocalCurrency.Currency)
                  }}
                </div>
                <div v-else class="text-center">
                  {{ $t("content.no_quote") }}
                </div>
              </template>
              <template v-else>
                <span class="small"
                  >{{ $t("content.deductible_amount") }}:
                  {{
                    option.Selfrisk | formatMoney(result.quoteResult.Currency)
                  }}</span
                >
                <div v-if="option.PremiumToPay" class="text-center">
                  {{ $t("content.price_after_discount") }}:
                  {{
                    option.PremiumToPay
                      | formatMoney(result.quoteResult.Currency)
                  }}
                </div>
                <div v-else class="text-center">
                  {{ $t("content.no_quote") }}.
                </div>
              </template>
            </b-card-text>
          </b-card>
        </div>
      </div>
    </div>
    <div v-else>
      {{ $t("content.nodeductibles") }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    inputsToEdit: {
      type: Object,
      required: true
    },
    state: {
      type: Object,
      required: true
    },
    result: {
      required: true
    }
  }
};
</script>

<style lang="scss">
.imt-quote {
  .card.selfrisk-card {
    border: 1px solid #95989a;
    border-radius: 14px;
    font-size: 16px;
    letter-spacing: 0px;
    height: 70px;
    margin: 2px;
    .card-body {
      padding: 0.25em;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title {
      font-weight: bold;
      color: #231f20;
    }
    .price {
      font-weight: bolder;
      color: #231f20;
    }
    &.selected {
      border: 4px solid #ff772d;
    }
  }
  .deductible {
    @media screen and (min-width: 1250px) {
      padding-right: 110px;
      padding-left: 110px;
    }
  }
}
</style>
