<template>
  <div class="customer-details">
    <validation-observer ref="content" tag="form">
      <div class="row">
        <template
          v-if="
            inputsToEdit.CustomerType === 2 &&
            contract.ContractData.Data.ContractSettings.CivicIdIsMandatory &&
            (!customerDetails.CivilServantNumber ||
              !countryStates.Countries[
                customerDetails.CountryCivilServantNumber
              ])
          "
        >
          <div class="col-md-6">
            <b-form-group :label="$t('content.civilservant_country')">
              <div class="content-area">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-select
                    label="Name"
                    :placeholder="$t('content.select_country')"
                    v-model="customerDetailsToEdit.CountryCivilServantNumber"
                    :clearable="false"
                    :reduce="(country) => country.Alpha3"
                    :options="countriesList"
                  >
                    <template #no-options="{}">
                      {{ $t("content.no_match") }}
                    </template>
                  </v-select>
                  <span class="small text-danger">{{ errors[0] }}</span>
                </validation-provider>
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group :label="$t('content.civilservant_number')">
              <div class="content-area">
                <validation-provider rules="required" v-slot="{ errors }">
                  <input
                    :placeholder="$t('content.civilservant_number')"
                    v-model="customerDetailsToEdit.CivilServantNumber"
                    class="form-control"
                  />
                  <span class="small text-danger">{{ errors[0] }}</span>
                </validation-provider>
              </div>
            </b-form-group>
          </div>
        </template>
        <div class="col-md-6" v-if="!customerDetails.Firstname">
          <b-form-group :label="$t('content.firstname')">
            <div class="content-area">
              <validation-provider
                rules="required|alpha_spaces"
                v-slot="{ errors }"
              >
                <input
                  v-model="customerDetailsToEdit.Firstname"
                  class="form-control"
                />
                <span class="small text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-6" v-if="!customerDetails.Lastname">
          <b-form-group :label="$t('content.lastname')">
            <div class="content-area">
              <validation-provider
                rules="required|alpha_spaces"
                v-slot="{ errors }"
              >
                <input
                  v-model="customerDetailsToEdit.Lastname"
                  class="form-control"
                />
                <span class="small text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </b-form-group>
        </div>
        <div
          class="col-md-6"
          v-if="inputsToEdit.CustomerType === 1 && !customerDetails.CompanyName"
        >
          <b-form-group :label="$t('content.company_name')">
            <div class="content-area">
              <validation-provider rules="required" v-slot="{ errors }">
                <input
                  v-model="customerDetailsToEdit.CompanyName"
                  class="form-control"
                />
                <span class="small text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-6" v-if="!customerDetails.Address">
          <b-form-group :label="$t('content.address')">
            <div class="content-area">
              <validation-provider rules="required" v-slot="{ errors }">
                <input
                  v-model="customerDetailsToEdit.Address"
                  class="form-control"
                />
                <span class="small text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-6" v-if="!customerDetails.City">
          <b-form-group :label="$t('content.city')">
            <div class="content-area">
              <validation-provider
                rules="required|alpha_spaces"
                v-slot="{ errors }"
              >
                <input
                  v-model="customerDetailsToEdit.City"
                  class="form-control"
                />
                <span class="small text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-6" v-if="!customerDetails.PostalCode">
          <b-form-group :label="$t('content.postal_code')">
            <div class="content-area">
              <validation-provider rules="required" v-slot="{ errors }">
                <input
                  v-model="customerDetailsToEdit.PostalCode"
                  class="form-control"
                />
                <span class="small text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </b-form-group>
        </div>
        <template
          v-if="
            inputsToEdit.CustomerType === 1 &&
            !customerDetails.VatNumber &&
            !customerDetails.CompanyNumber
          "
        >
          <div class="col-md-6">
            <b-form-group :label="$t('content.vatnr')">
              <div class="content-area">
                <validation-provider
                  :rules="companyIdRule ? 'required' : ''"
                  v-slot="{ errors }"
                >
                  <input
                    v-model="customerDetailsToEdit.VatNumber"
                    class="form-control"
                  />
                  <span class="small text-danger">{{ errors[0] }}</span>
                </validation-provider>
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group :label="$t('content.companynr')">
              <div class="content-area">
                <validation-provider
                  :rules="companyIdRule ? 'required' : ''"
                  v-slot="{ errors }"
                >
                  <input
                    v-model="customerDetailsToEdit.CompanyNumber"
                    class="form-control"
                  />
                  <span class="small text-danger">{{ errors[0] }}</span>
                </validation-provider>
              </div>
            </b-form-group>
          </div>
        </template>
      </div>
    </validation-observer>
  </div>
</template>

<script>
export default {
  props: {
    inputsToEdit: {
      type: Object,
      required: true,
    },
    customerDetails: {
      type: Object,
      required: true,
    },
    customerDetailsToEdit: {
      required: true,
    },
    contract: {
      type: Object,
      required: true,
    },
    countryStates: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  async mounted() {
    if (
      !this.countryStates.Countries[
        this.customerDetailsToEdit.CountryCivilServantNumber
      ]
    ) {
      this.customerDetailsToEdit.CountryCivilServantNumber = null;
    }
    this.$nextTick(() => {
      if (this.$refs.content.$children.length === 0) {
        this.$emit("is-empty");
      }
    });
  },
  computed: {
    countriesList() {
      return Object.values(this.countryStates.Countries);
    },
    companyIdRule() {
      return (
        !this.customerDetailsToEdit.VatNumber &&
        !this.customerDetailsToEdit.CompanyNumber
      );
    },
  },
};
</script>

<style lang="scss">
.imt-quote {
  .customer-details {
    @media screen and (min-width: 1250px) {
      padding-right: 110px;
      padding-left: 110px;
    }
  }
}
</style>
