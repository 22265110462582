<template>
  <div class="missing-information">
    <validation-observer ref="content" v-if="inputsToEdit" tag="form">
      <div class="row">
        <div class="col-md-6" v-if="!inputs.CustomerType">
          <validation-provider rules="required" v-slot="{ valid }">
            <b-form-group>
              <template v-slot:label>
                {{ $t("content.customer_type") }}
                <span class="text-danger">{{ !valid ? " *" : "" }}</span>
              </template>
              <div class="content-area">
                <b-form-radio
                  v-model="inputsToEdit.CustomerType"
                  name="customertype"
                  :value="1"
                  >{{ $t("content.company") }}</b-form-radio
                >
                <b-form-radio
                  v-model="inputsToEdit.CustomerType"
                  name="customertype"
                  :value="2"
                  >{{ $t("content.private") }}</b-form-radio
                >
              </div>
            </b-form-group>
          </validation-provider>
        </div>
        <div
          class="col-md-6"
          v-if="
            !countryStates.Countries[inputs.Country] ||
            (inputsToEdit.Country === 'USA' &&
              !countryStates.States[inputs.State])
          "
        >
          <b-form-group :label="$t('content.assured_country')">
            <div class="content-area">
              <div class="row">
                <div class="col">
                  <validation-provider rules="required">
                    <v-select
                      label="Name"
                      :placeholder="$t('content.select_country')"
                      v-model="inputsToEdit.Country"
                      :clearable="false"
                      :reduce="(country) => country.Alpha3"
                      :options="countriesList"
                      :disabled="inputs.Country === 'USA'"
                    >
                      <template #no-options="{}">{{
                        $t("content.no_match")
                      }}</template>
                    </v-select>
                  </validation-provider>
                </div>
                <div class="col" v-if="inputsToEdit.Country === 'USA'">
                  <validation-provider rules="required">
                    <v-select
                      label="Name"
                      :placeholder="$t('content.select_usstate')"
                      v-model="inputsToEdit.State"
                      :clearable="false"
                      :reduce="(state) => state.Alpha2"
                      :options="statesList"
                    >
                      <template #no-options="{}">{{
                        $t("content.no_match")
                      }}</template>
                    </v-select>
                  </validation-provider>
                </div>
              </div>
            </div>
          </b-form-group>
        </div>
        <div
          class="col-md-12"
          v-if="
            !countryStates.Countries[inputs.CountryFrom] ||
            !countryStates.Countries[inputs.CountryTo] ||
            (inputsToEdit.CountryFrom === 'USA' &&
              inputsToEdit.CountryTo === 'USA' &&
              (!countryStates.States[inputs.StateFrom] ||
                !countryStates.States[inputs.StateTo]))
          "
        >
          <b-form-group :label="$t('content.transport_org_dest')">
            <div class="content-area">
              <div class="row">
                <div class="col-md-6">
                  <validation-provider rules="required">
                    <v-select
                      label="Name"
                      :placeholder="$t('content.select_country')"
                      v-model="inputsToEdit.CountryFrom"
                      :clearable="false"
                      :reduce="(country) => country.Alpha3"
                      :options="countriesList"
                      :disabled="inputs.CountryFrom === 'USA'"
                    >
                      <template #no-options="{}">{{
                        $t("content.no_match")
                      }}</template>
                    </v-select>
                  </validation-provider>
                </div>
                <div class="col-md-6">
                  <validation-provider rules="required">
                    <v-select
                      label="Name"
                      :placeholder="$t('content.select_country')"
                      v-model="inputsToEdit.CountryTo"
                      :clearable="false"
                      :reduce="(country) => country.Alpha3"
                      :options="countriesList"
                      :disabled="inputs.CountryTo === 'USA'"
                    >
                      <template #no-options="{}">{{
                        $t("content.no_match")
                      }}</template>
                    </v-select>
                  </validation-provider>
                </div>
              </div>
              <div
                class="row"
                v-if="
                  inputsToEdit.CountryFrom === 'USA' &&
                  inputsToEdit.CountryTo === 'USA'
                "
              >
                <div class="col-md-6">
                  <validation-provider rules="required">
                    <v-select
                      label="Name"
                      :placeholder="$t('content.select_usstate')"
                      v-model="inputsToEdit.StateFrom"
                      :clearable="false"
                      :reduce="(state) => state.Alpha2"
                      :options="statesList"
                    >
                      <template #no-options="{}">{{
                        $t("content.no_match")
                      }}</template>
                    </v-select>
                  </validation-provider>
                </div>
                <div class="col-md-6">
                  <validation-provider rules="required">
                    <v-select
                      label="Name"
                      :placeholder="$t('content.select_usstate')"
                      v-model="inputsToEdit.StateTo"
                      :clearable="false"
                      :reduce="(state) => state.Alpha2"
                      :options="statesList"
                    >
                      <template #no-options="{}">{{
                        $t("content.no_match")
                      }}</template>
                    </v-select>
                  </validation-provider>
                </div>
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-6" v-if="showPackingMode">
          <validation-provider rules="required" v-slot="{ valid }">
            <b-form-group>
              <template v-slot:label>
                {{ $t("content.packingmode") }}
                <span class="text-danger">{{ !valid ? " *" : "" }}</span>
              </template>
              <div class="content-area">
                <div class="row">
                  <template
                    v-for="packingMode in getQuestionByTransportMode(
                      'PackingMode'
                    ).Items.filter((x) => x.Enabled)"
                  >
                    <div :key="packingMode.Id" class="col-md-12">
                      <b-form-radio
                        v-model="inputsToEdit.PackingMode"
                        name="packingmode"
                        :value="packingMode.Id"
                      >
                        {{ $t("content.packingmode_" + packingMode.Id) }}
                      </b-form-radio>
                    </div>
                  </template>
                </div>
              </div>
            </b-form-group>
          </validation-provider>
        </div>
        <div class="col-md-6" v-if="showStateOfGoods">
          <validation-provider rules="required" v-slot="{ valid }">
            <b-form-group>
              <template v-slot:label>
                {{ $t("content.stateofgoods") }}
                <span class="text-danger">{{ !valid ? " *" : "" }}</span>
              </template>
              <div class="content-area">
                <div class="row">
                  <template
                    v-for="stateOfGoods in getQuestionByTransportMode(
                      'StateOfGoods'
                    ).Items.filter((x) => x.Enabled)"
                  >
                    <div :key="stateOfGoods.Id" class="col-md-12">
                      <b-form-radio
                        v-model="inputsToEdit.StateOfGoods"
                        name="stateofgoods"
                        :value="stateOfGoods.Id"
                      >
                        {{ $t("content.stateofgoods_" + stateOfGoods.Id) }}
                      </b-form-radio>
                    </div>
                  </template>
                </div>
              </div>
            </b-form-group>
          </validation-provider>
        </div>
        <div
          class="col-md-6"
          v-if="
            inputs.IsTemperatureControlled !== true &&
            inputs.IsTemperatureControlled !== false
          "
        >
          <validation-provider rules="required" v-slot="{ valid }">
            <b-form-group>
              <template v-slot:label>
                {{ $t("content.temperature_controlled") }}
                <span class="text-danger">{{ !valid ? " *" : "" }}</span>
              </template>
              <div class="content-area">
                <b-form-radio
                  v-model="inputsToEdit.IsTemperatureControlled"
                  name="temperaturecontrolled"
                  :value="true"
                  >{{ $t("content.yes") }}</b-form-radio
                >
                <b-form-radio
                  v-model="inputsToEdit.IsTemperatureControlled"
                  name="temperaturecontrolled"
                  :value="false"
                  >{{ $t("content.no") }}</b-form-radio
                >
              </div>
            </b-form-group>
          </validation-provider>
        </div>
        <div
          class="col-md-6"
          v-if="
            inputs.IsDangerousGoods !== true &&
            inputs.IsDangerousGoods !== false
          "
        >
          <validation-provider rules="required" v-slot="{ valid }">
            <b-form-group>
              <template v-slot:label>
                {{ $t("content.dangerous_goods") }}
                <span class="text-danger">{{ !valid ? " *" : "" }}</span>
              </template>
              <div class="content-area">
                <b-form-radio
                  v-model="inputsToEdit.IsDangerousGoods"
                  name="dangerousgoods"
                  :value="true"
                  >{{ $t("content.yes") }}</b-form-radio
                >
                <b-form-radio
                  v-model="inputsToEdit.IsDangerousGoods"
                  name="dangerousgoods"
                  :value="false"
                  >{{ $t("content.no") }}</b-form-radio
                >
              </div>
            </b-form-group>
          </validation-provider>
        </div>
        <div class="col-md-6" v-if="showDgClasses">
          <validation-provider rules="required" v-slot="{ valid, validate }">
            <b-form-group>
              <template v-slot:label>
                Select suitable dangerous goods classes
                <span class="text-danger">{{ !valid ? " *" : "" }}</span>
              </template>
              <div class="content-area">
                <v-select
                  @search:blur="validate()"
                  @option:deselected="validate()"
                  label="Name"
                  :searchable="false"
                  multiple
                  :placeholder="$t('content.select')"
                  v-model="inputsToEdit.DangerousGoodsClasses"
                  :clearable="false"
                  :reduce="(option) => option.Id"
                  :options="getDangerousGoodsClasses"
                >
                  <template #option="option">
                    {{ option.Name }}
                  </template>
                  <template #selected-option="option">
                    <span style="padding: 5px">{{ option.Name }}</span>
                  </template>
                </v-select>
              </div>
            </b-form-group>
          </validation-provider>
        </div>
        <div class="col-md-6" v-if="!inputs.LoadingMode">
          <validation-provider rules="required" v-slot="{ valid, validate }">
            <b-form-group>
              <template v-slot:label>
                {{ $t("content.loadingmode") }}
                <span class="text-danger">{{ !valid ? " *" : "" }}</span>
              </template>
              <div class="content-area">
                <v-select
                  @search:blur="validate()"
                  label="Name"
                  :searchable="false"
                  :placeholder="$t('content.select')"
                  v-model="inputsToEdit.LoadingMode"
                  :clearable="false"
                  :reduce="(option) => option.Id"
                  :options="
                    getQuestionByTransportMode('LoadingMode').Items.filter(
                      (x) => x.Enabled
                    )
                  "
                >
                  <template #option="{ Id }">
                    {{ $t("content.loadingmode_" + Id) }}
                  </template>
                  <template #selected-option="{ Id }">
                    {{ $t("content.loadingmode_" + Id) }}
                  </template>
                </v-select>
              </div>
            </b-form-group>
          </validation-provider>
        </div>
        <div class="col-md-6" v-if="showOddSized">
          <validation-provider rules="required" v-slot="{ valid }">
            <b-form-group>
              <template v-slot:label>
                {{ $t("content.isoddsized") }}
                <span class="text-danger">{{ !valid ? " *" : "" }}</span>
              </template>
              <div class="content-area">
                <b-form-radio
                  v-model="inputsToEdit.IsOddSized"
                  name="oddsized"
                  :value="true"
                  >{{ $t("content.yes") }}</b-form-radio
                >
                <b-form-radio
                  v-model="inputsToEdit.IsOddSized"
                  name="oddsized"
                  :value="false"
                  >{{ $t("content.no") }}</b-form-radio
                >
              </div>
            </b-form-group>
          </validation-provider>
        </div>
        <template v-if="showOddSizedDimensions">
          <div class="col-md-12">
            <b-form-group
              label="Indicate the number of items with different dimensions"
            >
              <div class="content-area">
                <div class="row">
                  <div class="col-md-6">
                    <select
                      v-model="numberOfOddsizedItems"
                      @change="changedNumberOfOddsizedItems"
                      class="form-control"
                    >
                      <option v-for="val in 10" :key="val" :value="val">
                        {{ val }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div
                class="col-xs-12"
                v-if="inputsToEdit.OddSizedItems.length > 0"
              >
                <div class="content-area">
                  <div
                    class="row boxed-items mr-0 ml-0"
                    v-for="(item, index) in inputsToEdit.OddSizedItems"
                    :key="index"
                  >
                    <div class="form-group col-md-2">
                      <label>Quantity</label>
                      <validation-provider
                        rules="required|integer"
                        v-slot="{ errors }"
                      >
                        <money
                          v-model="item.Quantity"
                          v-bind="{ precision: 0 }"
                          class="form-control"
                        />
                        <span class="small text-danger">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Weight (kg)</label>
                      <validation-provider
                        rules="required|between:0,999999999"
                        v-slot="{ errors }"
                      >
                        <money v-model="item.Weight" class="form-control" />
                        <span class="small text-danger">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Length (m)</label>
                      <validation-provider
                        rules="required|between:0,999999999"
                        v-slot="{ errors }"
                      >
                        <money v-model="item.Length" class="form-control" />
                        <span class="small text-danger">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Width (m)</label>
                      <validation-provider
                        rules="required|between:0,999999999"
                        v-slot="{ errors }"
                      >
                        <money v-model="item.Width" class="form-control" />
                        <span class="small text-danger">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Height (m)</label>
                      <validation-provider
                        rules="required|between:0,999999999"
                        v-slot="{ errors }"
                      >
                        <money v-model="item.Height" class="form-control" />
                        <span class="small text-danger">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Total value ({{ inputsToEdit.Currency }})</label>
                      <validation-provider
                        rules="required|between:0,999999999"
                        v-slot="{ errors }"
                      >
                        <money v-model="item.TotalValue" class="form-control" />
                        <span class="small text-danger">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6" v-if="showLiftRate">
            <validation-provider rules="required" v-slot="{ valid }">
              <b-form-group>
                <template v-slot:label>
                  Is lift used?
                  <span class="text-danger">{{ !valid ? " *" : "" }}</span>
                </template>
                <div class="content-area">
                  <b-form-radio
                    v-model="inputsToEdit.IsLiftUsed"
                    name="liftrate"
                    :value="true"
                    >{{ $t("content.yes") }}</b-form-radio
                  >
                  <b-form-radio
                    v-model="inputsToEdit.IsLiftUsed"
                    name="liftrate"
                    :value="false"
                    >{{ $t("content.no") }}</b-form-radio
                  >
                </div>
              </b-form-group>
            </validation-provider>
          </div>
          <div class="col-md-6" v-if="showSurcharges">
            <validation-provider rules="required" v-slot="{ valid }">
              <b-form-group>
                <template v-slot:label>
                  Where is the cargo stacked?
                  <span class="text-danger">{{ !valid ? " *" : "" }}</span>
                </template>
                <div class="content-area">
                  <b-form-radio
                    v-model="inputsToEdit.IsOnDeck"
                    name="isondeck"
                    :value="true"
                    >On deck</b-form-radio
                  >
                  <b-form-radio
                    v-model="inputsToEdit.IsOnDeck"
                    name="isondeck"
                    :value="false"
                    >Below deck</b-form-radio
                  >
                </div>
              </b-form-group>
            </validation-provider>
          </div>
        </template>
        <template v-if="showBulkQuestions">
          <div class="col-md-6" v-if="showBulkType">
            <validation-provider rules="required" v-slot="{ valid }">
              <b-form-group>
                <template v-slot:label>
                  {{ $t("content.bulktype") }}
                  <span class="text-danger">{{ !valid ? " *" : "" }}</span>
                </template>
                <div class="content-area">
                  <div class="row">
                    <template
                      v-for="bulkType in getQuestionByTransportMode(
                        'BulkType'
                      ).Items.filter((x) => x.Enabled)"
                    >
                      <div :key="bulkType.Id" class="col-md-12">
                        <b-form-radio
                          v-model="inputsToEdit.BulkType"
                          name="bulktype"
                          :value="bulkType.Id"
                        >
                          {{ $t("content.bulktype_" + bulkType.Id) }}
                        </b-form-radio>
                      </div>
                    </template>
                  </div>
                </div>
              </b-form-group>
            </validation-provider>
          </div>
          <div class="col-md-6" v-if="showDedicatedVessel">
            <validation-provider rules="required" v-slot="{ valid }">
              <b-form-group>
                <template v-slot:label>
                  Is the vessel dedicated for these type of goods?
                  <span class="text-danger">{{ !valid ? " *" : "" }}</span>
                </template>
                <div class="content-area">
                  <b-form-radio
                    v-model="inputsToEdit.IsDedicatedVessel"
                    name="dedicatedvessel"
                    :value="true"
                    >{{ $t("content.yes") }}</b-form-radio
                  >
                  <b-form-radio
                    v-model="inputsToEdit.IsDedicatedVessel"
                    name="dedicatedvessel"
                    :value="false"
                    >{{ $t("content.no") }}</b-form-radio
                  >
                </div>
              </b-form-group>
            </validation-provider>
          </div>
          <div class="col-md-6" v-if="showVesselOwnership">
            <validation-provider rules="required" v-slot="{ valid }">
              <b-form-group>
                <template v-slot:label>
                  {{ $t("content.vesselownership") }}
                  <span class="text-danger">{{ !valid ? " *" : "" }}</span>
                </template>
                <div class="content-area">
                  <div class="row">
                    <template
                      v-for="vesselOwnership in getQuestionByTransportMode(
                        'VesselOwnership'
                      ).Items.filter((x) => x.Enabled)"
                    >
                      <div :key="vesselOwnership.Id" class="col-md-12">
                        <b-form-radio
                          v-model="inputsToEdit.VesselOwnership"
                          name="vesselownership"
                          :value="vesselOwnership.Id"
                        >
                          {{
                            $t("content.vesselownership_" + vesselOwnership.Id)
                          }}
                        </b-form-radio>
                      </div>
                    </template>
                  </div>
                </div>
              </b-form-group>
            </validation-provider>
          </div>
          <div class="col-md-6" v-if="showVesselAge">
            <validation-provider rules="required" v-slot="{ valid }">
              <b-form-group>
                <template v-slot:label>
                  {{ $t("content.vesselage") }}
                  <span class="text-danger">{{ !valid ? " *" : "" }}</span>
                </template>
                <div class="content-area">
                  <div class="row">
                    <template
                      v-for="vesselAge in getQuestionByTransportMode(
                        'VesselAge'
                      ).Items"
                    >
                      <div :key="vesselAge.Id" class="col-md-12">
                        <b-form-radio
                          v-model="inputsToEdit.VesselAge"
                          name="vesselage"
                          :value="vesselAge.Id"
                        >
                          {{ $t("content.vesselage_" + vesselAge.Id) }}
                        </b-form-radio>
                      </div>
                    </template>
                  </div>
                </div>
              </b-form-group>
            </validation-provider>
          </div>
        </template>
        <div class="col-md-6" v-if="showCargoRiskTypes">
          <validation-provider rules="required" v-slot="{ valid, validate }">
            <b-form-group>
              <template v-slot:label>
                {{ $t("content.cargo_risktype") }}
                <span class="text-danger">{{ !valid ? " *" : "" }}</span>
              </template>
              <div class="content-area">
                <v-select
                  @search:blur="validate()"
                  @option:deselected="validate()"
                  label="Name"
                  :searchable="false"
                  multiple
                  :placeholder="$t('content.select')"
                  v-model="inputsToEdit.RiskCategories"
                  :clearable="false"
                  :reduce="(option) => option.Id"
                  :options="
                    getQuestionByTransportMode('FreightServices').Items.filter(
                      (x) => x.Enabled
                    )
                  "
                >
                  <template #option="option">
                    {{ getCargoRiskTypeName(option) }}
                  </template>
                  <template #selected-option="option">
                    <span style="padding: 5px">{{
                      getCargoRiskTypeName(option)
                    }}</span>
                  </template>
                </v-select>
              </div>
            </b-form-group>
          </validation-provider>
        </div>
        <template v-if="showIncoterms">
          <div class="col-md-6">
            <validation-provider rules="required" v-slot="{ valid }">
              <b-form-group>
                <template v-slot:label>
                  {{ $t("content.clienttype") }}
                  <span class="text-danger">{{ !valid ? " *" : "" }}</span>
                </template>
                <div class="content-area">
                  <div class="row">
                    <div class="col-md-12">
                      <b-form-radio
                        v-model="inputsToEdit.ClientType"
                        name="clienttype"
                        :value="1"
                      >
                        {{ $t("content.clienttype_" + 1) }}
                      </b-form-radio>
                    </div>
                    <div class="col-md-12">
                      <b-form-radio
                        v-model="inputsToEdit.ClientType"
                        name="clienttype"
                        :value="2"
                      >
                        {{ $t("content.clienttype_" + 2) }}
                      </b-form-radio>
                    </div>
                  </div>
                </div>
              </b-form-group>
            </validation-provider>
          </div>
          <div class="col-md-6">
            <validation-provider rules="required" v-slot="{ valid, validate }">
              <b-form-group>
                <template v-slot:label>
                  Incoterms
                  <span class="text-danger">{{ !valid ? " *" : "" }}</span>
                </template>
                <div class="content-area">
                  <v-select
                    @search:blur="validate()"
                    label="Name"
                    :placeholder="$t('content.select')"
                    v-model="inputsToEdit.Incoterm"
                    :clearable="false"
                    :reduce="(option) => option.Id"
                    :options="incoterms"
                  >
                    <template #no-options="{}">{{
                      $t("content.no_match")
                    }}</template>
                  </v-select>
                </div>
              </b-form-group>
            </validation-provider>
          </div>
        </template>
        <div class="col-md-6" v-if="showLetterOfCredit">
          <validation-provider rules="required" v-slot="{ valid }">
            <b-form-group>
              <template v-slot:label>
                Have letter of credit?
                <span class="text-danger">{{ !valid ? " *" : "" }}</span>
              </template>
              <div class="content-area">
                <b-form-radio
                  v-model="inputsToEdit.HasLetterOfCredit"
                  name="letterofcredit"
                  :value="true"
                  >{{ $t("content.yes") }}</b-form-radio
                >
                <b-form-radio
                  v-model="inputsToEdit.HasLetterOfCredit"
                  name="letterofcredit"
                  :value="false"
                  >{{ $t("content.no") }}</b-form-radio
                >
              </div>
            </b-form-group>
          </validation-provider>
        </div>
        <div
          class="col-md-6"
          v-if="
            inputsToEdit.HasLetterOfCredit === true &&
            !inputs.LetterOfCreditNumber
          "
        >
          <validation-provider rules="required" v-slot="{ valid }">
            <b-form-group>
              <template v-slot:label>
                {{ $t("content.letter_credit") }}
                <span class="text-danger">{{ !valid ? " *" : "" }}</span>
              </template>
              <div class="content-area">
                <input
                  v-model="inputsToEdit.LetterOfCreditNumber"
                  class="form-control"
                />
              </div>
            </b-form-group>
          </validation-provider>
        </div>
        <div
          class="col-md-6"
          v-if="
            inputsToEdit.HasLetterOfCredit === true &&
            !inputs.LetterOfCreditText
          "
        >
          <validation-provider rules="required" v-slot="{ valid }">
            <b-form-group>
              <template v-slot:label>
                {{ $t("content.letter_credit_text") }}
                <span class="text-danger">{{ !valid ? " *" : "" }}</span>
              </template>
              <div class="content-area">
                <textarea
                  v-model="inputsToEdit.LetterOfCreditText"
                  class="form-control"
                />
              </div>
            </b-form-group>
          </validation-provider>
        </div>
        <div
          class="col-md-6"
          v-if="!inputs.ValueOfGoods || oddSizedItemsValueMismatch"
        >
          <b-form-group>
            <template
              v-slot:label
              v-if="
                !contract.ContractData.Data.ContractSettings
                  .ShowOptionalQuestions
              "
              >{{ $t("content.valueofgoods") }} (+
              <span style="text-transform: lowercase">{{
                $t("content.transport_charges")
              }}</span
              >)</template
            >
            <template v-slot:label v-else>{{
              $t("content.valueofgoods")
            }}</template>
            <div class="content-area">
              <validation-provider
                :rules="
                  oddSizedItemsIsActive
                    ? `required|oddsized_items_match:${oddSizedItemsTotalValue},${oddSizedItemsTotalValue}|min_value:0.01`
                    : 'required|min_value:0.01'
                "
                v-slot="{ errors }"
              >
                <b-input-group>
                  <template v-slot:append>
                    <b-input-group-text>
                      {{ inputsToEdit.Currency }}
                    </b-input-group-text>
                  </template>
                  <money
                    class="form-control"
                    v-model="inputsToEdit.ValueOfGoods"
                  ></money>
                </b-input-group>
                <span class="small text-danger">
                  <template v-if="errors[0] === 'oddsized_items_match'">
                    {{ $t("validation.oddsized_items_error") }} ({{
                      oddSizedItemsTotalValue
                        | formatMoney(inputsToEdit.Currency)
                    }})
                  </template>
                  <template v-else>{{ errors[0] }}</template>
                </span>
              </validation-provider>
            </div>
          </b-form-group>
        </div>
        <div
          class="col-md-6"
          v-if="
            !inputs.TransportationCharges &&
            contract.ContractData.Data.ContractSettings.ShowOptionalQuestions
          "
        >
          <b-form-group>
            <template v-slot:label>{{
              $t("content.transport_charges")
            }}</template>
            <div class="content-area">
              <b-input-group>
                <template v-slot:append>
                  <b-input-group-text>
                    {{ inputsToEdit.Currency }}
                  </b-input-group-text>
                </template>
                <money
                  class="form-control"
                  v-model="inputsToEdit.TransportationCharges"
                ></money>
              </b-input-group>
            </div>
          </b-form-group>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
export default {
  props: {
    inputs: {
      type: Object,
      required: true,
    },
    inputsToEdit: {
      type: Object,
      required: true,
    },
    state: {
      type: Object,
      required: true,
    },
    contract: {
      type: Object,
      required: true,
    },
    countryStates: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      numberOfOddsizedItems: 1,
    };
  },
  mounted() {
    this.checkCountryOfOrigin();
    this.checkTransportCountries();
    this.$nextTick(() => {
      if (this.$refs.content.$children.length === 0) {
        this.$emit("is-empty");
      }
    });
  },
  watch: {
    "inputsToEdit.LoadingMode"(val) {
      if (val === 1 || val === 2 || val === 5) {
        this.inputsToEdit.IsOddSized = null;
      }
    },
    "inputsToEdit.IsOddSized"(val) {
      if (val && this.showOddSizedDimensions) {
        this.changedNumberOfOddsizedItems();
      }
    },
  },
  computed: {
    showPackingMode() {
      if (!this.inputs.PackingMode) {
        const byMode = this.getQuestionByTransportMode("PackingMode");
        if (byMode.CustomizationMode === 0) {
          return true;
        }
      }
      return false;
    },
    showStateOfGoods() {
      if (!this.inputs.StateOfGoods) {
        const byMode = this.getQuestionByTransportMode("StateOfGoods");
        if (byMode.CustomizationMode === 0) {
          return true;
        }
      }
      return false;
    },
    showLiftRate() {
      if (this.inputs.IsLiftUsed !== false && this.inputs.IsLiftUsed !== true) {
        const byMode = this.getQuestionByTransportMode("LiftRate");
        if (byMode.CustomizationMode === 0) {
          return true;
        }
      }
      return false;
    },
    showSurcharges() {
      if (
        this.inputs.IsOnDeck !== false &&
        this.inputs.IsOnDeck !== true &&
        this.inputsToEdit.TransportMode === 3
      ) {
        const byMode = this.getQuestionByTransportMode("Surcharges");
        if (byMode.CustomizationMode === 0) {
          return true;
        }
      }
      return false;
    },
    showBulkType() {
      if (this.inputs.BulkType !== false && this.inputs.BulkType !== true) {
        const byMode = this.getQuestionByTransportMode("BulkType");
        if (byMode.CustomizationMode === 0) {
          return true;
        }
      }
      return false;
    },
    showDedicatedVessel() {
      if (
        this.inputs.IsDedicatedVessel !== false &&
        this.inputs.IsDedicatedVessel !== true
      ) {
        const byMode = this.getQuestionByTransportMode("DedicatedVessel");
        if (byMode.CustomizationMode === 0) {
          return true;
        }
      }
      return false;
    },
    showVesselOwnership() {
      if (!this.inputs.VesselOwnership) {
        const byMode = this.getQuestionByTransportMode("VesselOwnership");
        if (byMode.CustomizationMode === 0) {
          return true;
        }
      }
      return false;
    },
    showVesselAge() {
      if (!this.inputs.VesselAge) {
        const byMode = this.getQuestionByTransportMode("VesselAge");
        if (byMode.CustomizationMode === 0) {
          return true;
        }
      }
      return false;
    },
    getDangerousGoodsClasses() {
      const byMode = this.getQuestionByTransportMode("DangerousGoodsClasses");
      if (byMode.CustomizationMode === 1) {
        return [];
      }
      return byMode.SelectedDgClasses.filter((x) => x.Enabled);
    },
    showOddSizedDimensions() {
      if (this.showOddSized && this.inputsToEdit.IsOddSized === true) {
        const byMode = this.getQuestionByTransportMode("OddSizedExceeds");
        if (byMode.CustomizationMode === 0) {
          return true;
        }
      }
      return false;
    },
    countriesList() {
      return Object.values(this.countryStates.Countries);
    },
    statesList() {
      return Object.values(this.countryStates.States);
    },
    showOddSized() {
      if (
        this.inputsToEdit.LoadingMode === 3 ||
        this.inputsToEdit.LoadingMode === 4 ||
        this.inputsToEdit.LoadingMode === 6 ||
        this.inputsToEdit.LoadingMode === 7 ||
        this.inputsToEdit.LoadingMode === 8
      ) {
        return (
          (this.inputs.IsOddSized !== true &&
            this.inputs.IsOddSized !== false) ||
          !this.inputs.LoadingMode
        );
      }
      return false;
    },
    oddSizedItemsIsActive() {
      if (
        this.inputsToEdit.LoadingMode === 3 ||
        this.inputsToEdit.LoadingMode === 4 ||
        this.inputsToEdit.LoadingMode === 6 ||
        this.inputsToEdit.LoadingMode === 7 ||
        this.inputsToEdit.LoadingMode === 8
      ) {
        return (
          this.inputsToEdit.IsOddSized === true &&
          this.inputsToEdit.OddSizedItems.length > 0
        );
      }
      return false;
    },
    oddSizedItemsTotalValue() {
      if (this.oddSizedItemsIsActive) {
        return this._.sumBy(this.inputsToEdit.OddSizedItems, "TotalValue");
      } else {
        return 0;
      }
    },
    oddSizedItemsValueMismatch() {
      if (this.oddSizedItemsIsActive) {
        return this.oddSizedItemsTotalValue !== this.inputs.ValueOfGoods;
      } else {
        return false;
      }
    },
    showBulkQuestions() {
      return this.inputsToEdit.LoadingMode === 5;
    },
    showCargoRiskTypes() {
      if (this.inputs.HsCodes.length > 0) {
        return false;
      }
      const items = this.getQuestionByTransportMode("FreightServices").Items;
      if (this.inputs.RiskCategories.length > 0) {
        const item = items.find((x) => x.Id === this.inputs.RiskCategories[0]);
        return !item;
      }
      return true;
    },
    showDgClasses() {
      if (this.getDangerousGoodsClasses.length === 0) {
        return false;
      }
      if (this.inputsToEdit.IsDangerousGoods) {
        if (this.inputs.DangerousGoodsClasses.length > 0) {
          const item = this.getDangerousGoodsClasses.find(
            (x) => x.Id === this.inputs.DangerousGoodsClasses[0]
          );
          return !item;
        }
        return true;
      }
      return false;
    },
    showIncoterms() {
      if (!this.inputs.Incoterm || !this.inputs.ClientType) {
        const byMode = this.getQuestionByTransportMode("Incoterms");
        if (byMode.CustomizationMode === 0) {
          return true;
        }
      }
      return false;
    },
    showLetterOfCredit() {
      if (
        this.inputs.HasLetterOfCredit !== false &&
        this.inputs.HasLetterOfCredit !== true
      ) {
        const byMode = this.getQuestionByTransportMode("LetterOfCredits");
        if (byMode.CustomizationMode === 0) {
          return true;
        }
      }
      return false;
    },
    incoterms() {
      return [
        { Id: "EXW", Name: "EXW" },
        { Id: "FCA", Name: "FCA" },
        { Id: "FAS", Name: "FAS" },
        { Id: "FOB", Name: "FOB" },
        { Id: "CFR", Name: "CFR" },
        { Id: "CIF", Name: "CIF" },
        { Id: "CPT", Name: "CPT" },
        { Id: "CIP", Name: "CIP" },
        { Id: "DPU", Name: "DPU" },
        { Id: "DAP", Name: "DAP" },
        { Id: "DDP", Name: "DDP" },
      ];
    },
  },
  methods: {
    checkTransportCountries() {
      const countryFrom =
        this.countryStates.Countries[this.inputsToEdit.CountryFrom];
      if (!countryFrom) {
        this.inputsToEdit.CountryFrom = null;
      }
      const countryTo =
        this.countryStates.Countries[this.inputsToEdit.CountryTo];
      if (!countryTo) {
        this.inputsToEdit.CountryTo = null;
      }
      if (
        this.inputsToEdit.CountryFrom === "USA" &&
        this.inputsToEdit.CountryTo === "USA"
      ) {
        const stateFrom =
          this.countryStates.States[this.inputsToEdit.StateFrom];
        if (!stateFrom) {
          this.inputsToEdit.StateFrom = null;
        }
        const stateTo = this.countryStates.States[this.inputsToEdit.StateTo];
        if (!stateTo) {
          this.inputsToEdit.StateTo = null;
        }
      }
    },
    checkCountryOfOrigin() {
      const country = this.countryStates.Countries[this.inputsToEdit.Country];
      if (!country) {
        this.inputsToEdit.Country = null;
      }
    },
    changedNumberOfOddsizedItems() {
      this.inputsToEdit.OddSizedItems = [];
      for (let index = 0; index < this.numberOfOddsizedItems; index++) {
        const item = {
          Quantity: 0,
          Weight: 0,
          Length: 0,
          Width: 0,
          Height: 0,
          TotalValue: 0,
        };
        this.inputsToEdit.OddSizedItems.push(item);
      }
    },
    getQuestionByTransportMode(val) {
      return this.contract.ContractData.Data.ContractQuestions.find(
        (q) => q.OptionsApi === val
      ).Options.find(
        (o) => o.TransportModeId === this.inputsToEdit.TransportMode
      );
    },
    getCargoRiskTypeName(option) {
      const key = this.state.tenantSettings.TenantKey;
      if (key === "ALA" || key === "ALT" || key === "IMTA") {
        return this.$t("content.cargo_risktype_ALA_" + option.Id);
      } else {
        return option.Name;
      }
    },
  },
};
</script>
<style lang="scss">
.imt-quote {
  .vs__selected button {
    padding: 10px;
  }
  .badge.custom-badge {
    line-height: 1.5;
    white-space: break-spaces;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: left;
  }
  .badge-orange {
    color: #fff;
    background-color: #ff772d;
  }
  .badge-inactive {
    color: #767676;
    background-color: #efefef;
  }
  .missing-information {
    @media screen and (min-width: 1250px) {
      padding-right: 110px;
      padding-left: 110px;
    }
    .boxed-items {
      background: #efefef;
      padding: 20px;
      margin-bottom: 15px;
      border-radius: 10px;
      @media (max-width: 1200px) {
        .col-md-2 {
          // Todo: no support on IE11 for 'unset'
          flex-basis: unset;
          flex-grow: unset;
          flex-shrink: unset;
          max-width: unset;
        }
      }
    }
    legend {
      color: #767676 !important;
      border: none;
    }
  }
}
</style>
