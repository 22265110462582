var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{class:{ active: _vm.tab.active }},[_c('a',{staticClass:"disabled",attrs:{"href":"javascript:void(0)"}},[_c('div',{staticClass:"wizard-icon-circle md",class:{
        checked: _vm.tab.checked,
        square_shape: _vm.isStepSquare,
        tab_shape: _vm.isTabShape
      },style:([
        _vm.tab.checked ? _vm.stepCheckedStyle : {},
        _vm.tab.validationError ? _vm.errorStyle : {}
      ]),attrs:{"role":"tab","tabindex":_vm.tab.checked ? 0 : '',"id":("step-" + (_vm.tab.tabId)),"aria-controls":_vm.tab.tabId,"aria-disabled":_vm.tab.active,"aria-selected":_vm.tab.active}},[_c('transition',{attrs:{"name":_vm.transition,"mode":"out-in"}},[(_vm.tab.active)?_c('div',{staticClass:"wizard-icon-container",class:{ square_shape: _vm.isStepSquare, tab_shape: _vm.isTabShape },style:([
            _vm.tab.active ? _vm.iconActiveStyle : {},
            _vm.tab.validationError ? _vm.errorStyle : {}
          ])},[_vm._t("active-step",function(){return [_c('span',{staticClass:"stepTitle",class:{ active: _vm.tab.active, has_error: _vm.tab.validationError },style:(_vm.tab.active ? _vm.stepTitleStyle : {})},[_vm._v(" "+_vm._s(_vm.tab.title)+" ")])]})],2):_vm._e(),(!_vm.tab.active)?_vm._t("default",function(){return [_c('span',{staticClass:"stepTitle",class:{ active: _vm.tab.active, has_error: _vm.tab.validationError },style:(_vm.tab.active ? _vm.stepTitleStyle : {})},[_vm._v(" "+_vm._s(_vm.tab.title)+" ")])]}):_vm._e()],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }