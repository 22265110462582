var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"additional-information"},[(_vm.additionalsToEdit)?_c('validation-observer',{ref:"content",attrs:{"tag":"form"}},[_c('div',{staticClass:"row"},[(
          _vm.showField('ExpectedInsuranceStartDate') &&
          (!_vm.startDateIsValid || !_vm.additionalQuestions.ExpectedStartDate)
        )?_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.insurance_startdate')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":_vm.requiredField('ExpectedInsuranceStartDate') ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-datepicker',{key:_vm.endDateTimeStamp,attrs:{"hide-header":true,"start-weekday":1,"min":new Date(),"max":_vm.additionalsToEdit.ExpectedEndDate,"name":"StartDate"},on:{"input":function($event){_vm.startDateTimeStamp = new Date().getTime()}},model:{value:(_vm.additionalsToEdit.ExpectedStartDate),callback:function ($$v) {_vm.$set(_vm.additionalsToEdit, "ExpectedStartDate", $$v)},expression:"additionalsToEdit.ExpectedStartDate"}}),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2320883512)})],1)])],1):_vm._e(),(
          _vm.showField('ExpectedInsuranceEndDate') &&
          (!_vm.endDateIsValid || !_vm.additionalQuestions.ExpectedEndDate)
        )?_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.insurance_enddate')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":_vm.requiredField('ExpectedInsuranceEndDate') ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-datepicker',{key:_vm.startDateTimeStamp,attrs:{"hide-header":true,"start-weekday":1,"min":_vm.additionalsToEdit.ExpectedStartDate,"name":"EndDate"},on:{"input":function($event){_vm.endDateTimeStamp = new Date().getTime()}},model:{value:(_vm.additionalsToEdit.ExpectedEndDate),callback:function ($$v) {_vm.$set(_vm.additionalsToEdit, "ExpectedEndDate", $$v)},expression:"additionalsToEdit.ExpectedEndDate"}}),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,991858492)})],1)])],1):_vm._e(),(
          _vm.showField('DescriptionOfCargo') &&
          !_vm.additionalQuestions.CargoDescription
        )?_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.cargo_description')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":_vm.requiredField('DescriptionOfCargo') ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.additionalsToEdit.CargoDescription),expression:"additionalsToEdit.CargoDescription"}],staticClass:"form-control",domProps:{"value":(_vm.additionalsToEdit.CargoDescription)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.additionalsToEdit, "CargoDescription", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2795451450)})],1)])],1):_vm._e(),(
          _vm.showField('MarksAndNumbers') && !_vm.additionalQuestions.MarksAndNumbers
        )?_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.marks_numbers')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":_vm.requiredField('MarksAndNumbers') ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.additionalsToEdit.MarksAndNumbers),expression:"additionalsToEdit.MarksAndNumbers"}],staticClass:"form-control",domProps:{"value":(_vm.additionalsToEdit.MarksAndNumbers)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.additionalsToEdit, "MarksAndNumbers", $event.target.value)}}}),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1458659234)})],1)])],1):_vm._e(),(_vm.showField('VesselName') && !_vm.additionalQuestions.VesselName)?_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.vessel_name')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":_vm.requiredField('VesselName') ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.additionalsToEdit.VesselName),expression:"additionalsToEdit.VesselName"}],staticClass:"form-control",domProps:{"value":(_vm.additionalsToEdit.VesselName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.additionalsToEdit, "VesselName", $event.target.value)}}}),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2614595170)})],1)])],1):_vm._e(),(_vm.showField('Carrier') && !_vm.additionalQuestions.Carrier)?_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.carrier')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":_vm.requiredField('Carrier') ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.additionalsToEdit.Carrier),expression:"additionalsToEdit.Carrier"}],staticClass:"form-control",domProps:{"value":(_vm.additionalsToEdit.Carrier)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.additionalsToEdit, "Carrier", $event.target.value)}}}),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2712178562)})],1)])],1):_vm._e(),(_vm.showField('VoyageNumber') && !_vm.additionalQuestions.VoyageNumber)?_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.voyage')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":_vm.requiredField('VoyageNumber') ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.additionalsToEdit.VoyageNumber),expression:"additionalsToEdit.VoyageNumber"}],staticClass:"form-control",domProps:{"value":(_vm.additionalsToEdit.VoyageNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.additionalsToEdit, "VoyageNumber", $event.target.value)}}}),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,186634754)})],1)])],1):_vm._e(),(_vm.showNamedPlaceOfIncoterm())?_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.named_place')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":_vm.requiredField('NamedPlaceOfIncoterm') ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.additionalsToEdit.IncotermNamedPlace),expression:"additionalsToEdit.IncotermNamedPlace"}],staticClass:"form-control",domProps:{"value":(_vm.additionalsToEdit.IncotermNamedPlace)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.additionalsToEdit, "IncotermNamedPlace", $event.target.value)}}}),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2699426722)})],1)])],1):_vm._e(),(_vm.showField('OriginCity') && !_vm.additionalQuestions.OriginCity)?_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.origin_city')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":_vm.requiredField('OriginCity')
                  ? 'required|alpha_spaces'
                  : 'alpha_spaces'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.additionalsToEdit.OriginCity),expression:"additionalsToEdit.OriginCity"}],staticClass:"form-control",domProps:{"value":(_vm.additionalsToEdit.OriginCity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.additionalsToEdit, "OriginCity", $event.target.value)}}}),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1471933154)})],1)])],1):_vm._e(),(
          _vm.showField('LoadingCountry') &&
          !_vm.countryStates.Countries[_vm.additionalQuestions.LoadingCountry]
        )?_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.loading_country')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":_vm.requiredField('LoadingCountry') ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Name","placeholder":_vm.$t('content.select_country'),"clearable":false,"reduce":function (country) { return country.Alpha3; },"options":_vm.countriesList},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" "+_vm._s(_vm.$t("content.no_match"))+" ")]}}],null,true),model:{value:(_vm.additionalsToEdit.LoadingCountry),callback:function ($$v) {_vm.$set(_vm.additionalsToEdit, "LoadingCountry", $$v)},expression:"additionalsToEdit.LoadingCountry"}}),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,689156880)})],1)])],1):_vm._e(),(_vm.showField('LoadingCity') && !_vm.additionalQuestions.LoadingCity)?_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.loading_city')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":_vm.requiredField('LoadingCity')
                  ? 'required|alpha_spaces'
                  : 'alpha_spaces'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.additionalsToEdit.LoadingCity),expression:"additionalsToEdit.LoadingCity"}],staticClass:"form-control",domProps:{"value":(_vm.additionalsToEdit.LoadingCity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.additionalsToEdit, "LoadingCity", $event.target.value)}}}),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1314673634)})],1)])],1):_vm._e(),(
          _vm.showField('DischargeCountry') &&
          !_vm.countryStates.Countries[_vm.additionalQuestions.DischargeCountry]
        )?_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.discharge_country')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":_vm.requiredField('DischargeCountry') ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Name","placeholder":_vm.$t('content.select_country'),"clearable":false,"reduce":function (country) { return country.Alpha3; },"options":_vm.countriesList},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" "+_vm._s(_vm.$t("content.no_match"))+" ")]}}],null,true),model:{value:(_vm.additionalsToEdit.DischargeCountry),callback:function ($$v) {_vm.$set(_vm.additionalsToEdit, "DischargeCountry", $$v)},expression:"additionalsToEdit.DischargeCountry"}}),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1897363378)})],1)])],1):_vm._e(),(
          _vm.showField('DischargeCity') && !_vm.additionalQuestions.DischargeCity
        )?_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.discharge_city')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":_vm.requiredField('DischargeCity')
                  ? 'required|alpha_spaces'
                  : 'alpha_spaces'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.additionalsToEdit.DischargeCity),expression:"additionalsToEdit.DischargeCity"}],staticClass:"form-control",domProps:{"value":(_vm.additionalsToEdit.DischargeCity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.additionalsToEdit, "DischargeCity", $event.target.value)}}}),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1632840418)})],1)])],1):_vm._e(),(
          _vm.showField('DestinationCity') && !_vm.additionalQuestions.DestinationCity
        )?_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.destination_city')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":_vm.requiredField('DestinationCity')
                  ? 'required|alpha_spaces'
                  : 'alpha_spaces'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.additionalsToEdit.DestinationCity),expression:"additionalsToEdit.DestinationCity"}],staticClass:"form-control",domProps:{"value":(_vm.additionalsToEdit.DestinationCity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.additionalsToEdit, "DestinationCity", $event.target.value)}}}),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2228791010)})],1)])],1):_vm._e(),(
          _vm.showField('ReferenceNumber') && !_vm.additionalQuestions.ReferenceNumber
        )?_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":_vm.$t('content.reference_number')}},[_c('div',{staticClass:"content-area"},[_c('validation-provider',{attrs:{"rules":_vm.requiredField('ReferenceNumber') ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.additionalsToEdit.ReferenceNumber),expression:"additionalsToEdit.ReferenceNumber"}],staticClass:"form-control",domProps:{"value":(_vm.additionalsToEdit.ReferenceNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.additionalsToEdit, "ReferenceNumber", $event.target.value)}}}),_c('span',{staticClass:"small text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1881346690)})],1)])],1):_vm._e()])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }