import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en',
  messages: {
    en: {
      content: {
        select: 'Select',
        add_insurance_title: 'Get quote in a few clicks',
        no_offer: 'This shipment cannot be covered',
        no_quote: 'No quote',
        loading: 'Loading',
        add_insurance_btn: 'Continue',
        add_insurance_confirm_btn: 'Continue',
        add_quote_text: 'Add cargo insurance for',
        get_quote: 'Get quote',
        missing_information: 'Missing information',
        customer_details: 'Customer details',
        additional_information: 'Additional information',
        price_plan: 'Price Plan',
        deductible: 'Deductible',
        confirmation: 'Confirmation',
        next: 'Next',
        back: 'Back',
        premium_to_pay: 'Premium to pay',
        customer_type: 'Are you acting on private or a company behalf?',
        company: 'Company',
        private: 'Private',
        assured_country:
          'What is the country of establishment of the Assured (final beneficiary of the coverage)?',
        select_country: 'Select country',
        select_usstate: 'Select US State',
        transport_org_dest: 'Transport origin and destination',
        packingmode: 'Who packed the goods?',
        packingmode_1: 'Professional',
        packingmode_2: 'Owner',
        packingmode_3: 'Carrier',
        stateofgoods: "What's the state of the goods?",
        stateofgoods_1: 'New',
        stateofgoods_2: 'Used',
        stateofgoods_3: 'Reconditioned',
        loadingmode: 'Indicate how the goods are loaded and shipped',
        loadingmode_1: 'FCL / FTL / ULD',
        loadingmode_2: 'LCL / LTL',
        loadingmode_3: 'Roll-on / Roll-off',
        loadingmode_4: 'Project / Oversized / Special / Heavy-lift Cargo',
        loadingmode_5: 'Bulk',
        loadingmode_6: 'Break Bulk',
        loadingmode_7: 'Flatbed / Dropdeck',
        loadingmode_8: 'Out of gauge',
        isoddsized: 'Wheeled cargo and/or other odd sized industrial goods?',
        bulktype: 'Which type of bulk?',
        bulktype_1: 'Dry',
        bulktype_2: 'Gas',
        bulktype_3: 'Liquid',
        vesselownership: 'By whom is the vessel owned?',
        vesselownership_1: 'Chartered',
        vesselownership_2: 'Own',
        vesselownership_3: 'Third Party Carrier',
        vesselage: 'Age of vessel',
        vesselage_1: '0 - 10',
        vesselage_2: '11 - 15',
        vesselage_3: '16 - 25',
        vesselage_4: '26+',
        clienttype: 'Role in goods transaction',
        clienttype_1: 'Buyer/Importer',
        clienttype_2: 'Seller/Exporter',
        yes: 'Yes',
        no: 'No',
        cargo_risktype: 'Category Risk type',
        cargo_risktype_ALA_1:
          'Low value raw materials, waste products & Scrap (non-dangerous)',
        cargo_risktype_ALA_2: 'General Cargo (Commercial Items)',
        cargo_risktype_ALA_3:
          'Goods with Higher Risk (Industrial Goods - Manufactured Products & Goods)',
        cargo_risktype_ALA_4: '(Motorised) Vehicles',
        cargo_risktype_ALA_5: 'Removal Goods (Office & Personal)',
        cargo_risktype_ALA_6: 'Foodstuff & Perishables',
        cargo_risktype_ALA_7: 'Time & Theft Sensitive Cargo',
        cargo_risktype_ALA_8: 'Fragile & Vulnerable Cargo',
        cargo_risktype_ALA_9: 'Higher valued Dry, Gas & Liquid Bulk',
        cargo_risktype_ALA_10:
          'Specie, Liquor/Spirits & Tobacco products, Leather, Skin & Hides',
        cargo_risktype_ALA_11:
          'Corpses, Human Remains, Organs, Human & Animal Blood',
        cargo_risktype_ALA_12: 'Livestock, Trees & Plants',
        cargo_risktype_ALA_13: 'Other/Unknown',
        temperature_controlled: 'The goods needs to be temperature controlled',
        dangerous_goods: 'The goods are considered dangerous',
        letter_credit: 'Letter of Credit Number',
        letter_credit_text: 'Letter of Credit Text',
        valueofgoods: 'Value of the goods',
        transport_charges: 'Transportation charges',
        icc_a: 'All Risk - A',
        icc_b: 'Named perils only - B',
        icc_c: 'Total loss only - C',
        included: 'Risks covered',
        add_ons: 'Select extra clauses (optional)',
        add_ons_incl: 'Included applicable special clauses',
        max_liability: 'Max. liability',
        civilservant_country: 'Civil Servant No. Country of Issue',
        civilservant_number: 'Civil Servant Number',
        firstname: 'First name',
        company_name: 'Company name',
        lastname: 'Last name',
        country: 'Country',
        state: 'State/Province',
        city: 'City',
        address: 'Address',
        postal_code: 'Postal code',
        phone: 'Phone',
        vatnr: 'Vat Number',
        dunsnr: 'D-U-N-S Number',
        companynr: 'Company Number',
        insurance_startdate: 'Start date of Insurance',
        insurance_enddate: 'End date of Insurance',
        cargo_description: 'Description of the cargo',
        marks_numbers: 'Marks and numbers',
        carrier: 'Carrier',
        vessel_name: 'Vessel/Vehicle name',
        voyage: 'Voyage',
        named_place: 'Named place of the INCOTERM applied',
        origin_city:
          'Origin City (Place or city where the insurance takes effect)',
        loading_country:
          'Loading Country (where the port/airport of loading is located)',
        loading_city:
          'Loading City (where the port/airport of loading is located)',
        discharge_country:
          'Discharge Country (where the port/airport of loading is located)',
        discharge_city:
          'Discharge City (where the port/airport of unloading is located)',
        destination_city:
          'Destination City (Place or city where the insurance coverage ends)',
        reference_number: 'Reference Number',
        nodeductibles: 'No deductibles apply.',
        deductible_amount: 'Deductible amount',
        price_after_discount: 'Price after discount',
        cant_offer: "Can't offer quote",
        details: 'Details',
        insured_value: 'Insured Value',
        gross_premium: 'Gross Premium',
        deductible_discounts: 'Deductible Discounts',
        customer_discount: 'Customer Discounts',
        other_discounts: 'Other Discounts',
        vat: 'VAT',
        ipt: 'IPT',
        no_match: 'No match',
        general_terms_text:
          'I understand the roles of the various parties and have read and agree to be bound by their',
        terms_of_business_privacy_policy: 'Terms of Business & Privacy Policy',
        insurance_terms_text: 'I have read and agree to be bound by the',
        insurance_clauses_conditions:
          'Insurance Clauses, Standard & Special Conditions',
        insurance_terms_text_2: 'and am familiar with the',
        insurance_terms_conditions: 'Insurance Terms & Conditions',
        insurance_terms_text_3: 'and',
        data_processing_info: 'Data Processing Information',
        countries: 'countries',
        states: 'states',
        restrictions_text:
          "I accept that the insurance cover doesn't include transport modes in the following",
        terms_of_business: 'Terms of Business',
        privacy_policy: 'Privacy Policy',
        order_not_cancellable: "This order can't be cancelled",
        order_cancel_text: 'This order can be cancelled latest',
        order_cancel_text_2:
          'day(s) before commencement of the insured transit',
        show_information: 'Show information',
      },
      validation: {
        required: 'This field is required',
        email: 'Invalid e-mail',
        phone_regex: 'Invalid character input',
        alpha_spaces:
          'Only alphabetic characters as well as spaces are accepted',
        between: 'Only decimals are accepted',
        oddsized_items_match: 'oddsized_items_match',
        oddsized_items_error:
          'Input should match the total value of odd-sized items',
        integer: 'Only integers are accepted',
        min_value: 'Required',
      },
    },
    sv: {
      content: {
        select: 'Välj',
        add_insurance_title: 'Få ett pris på några klick',
        no_offer: 'Inget erbjudande',
        no_quote: 'Inget pris',
        loading: 'Laddar',
        add_insurance_btn: 'Fortsätt',
        add_insurance_confirm_btn: 'Continue',
        add_quote_text: 'Lägg till försäkring för',
        get_quote: 'Få ett pris',
        missing_information: 'Uppgifter saknas',
        customer_details: 'Kunduppgifter',
        additional_information: 'Ytterligare uppgifter',
        price_plan: 'Prisplan',
        deductible: 'Självriskrabatt',
        confirmation: 'Slutför',
        next: 'Nästa',
        back: 'Tillbaka',
        premium_to_pay: 'Premie att betala',
        customer_type:
          'Agerar du som privatperson eller på ett företags vägnar?',
        company: 'Företag',
        private: 'Privat',
        assured_country: 'Vilket är försäkringstagarens bosättningsland?',
        select_country: 'Välj land',
        select_usstate: 'Välj amerikansk delstat',
        transport_org_dest: 'Transportens avsändnings- och destinationsland',
        loadingmode: 'Ange hur varorna laddas och skickas',
        loadingmode_1: 'FCL / FTL / ULD (Hellaster)',
        loadingmode_2: 'LCL / LTL (Stycke- och partigods)',
        loadingmode_3: 'Roll-on / Roll-off',
        loadingmode_4: 'Projekt / Överdimensionerad / Special / Tunga lyft',
        loadingmode_5: 'Bulk',
        loadingmode_6: 'Break Bulk',
        loadingmode_7: 'Flatbed / Dropdeck',
        loadingmode_8: 'Out of gauge',
        isoddsized:
          'Hjullast och/eller andra överdimensionerade industrivaror?',
        yes: 'Ja',
        no: 'Nej',
        cargo_risktype: 'Lastrisktyp',
        cargo_risktype_ALA_1:
          'Råvaror med låg värde, skräpprodukter och skrot (icke-farligt)',
        cargo_risktype_ALA_2: 'Normalgods (kommersiella varor)',
        cargo_risktype_ALA_3:
          'Gods med förhöjd risk (industriella varor, tillverkade produkter och varor)',
        cargo_risktype_ALA_4: '(Motoriserade) fordon',
        cargo_risktype_ALA_5: 'Flyttegods (bohag- och kontorsflyttar)',
        cargo_risktype_ALA_6: 'Livsmedel & färskvaror',
        cargo_risktype_ALA_7: 'Tid- och stöldkänsliga varor',
        cargo_risktype_ALA_8: 'Bräckliga och sårbara varor',
        cargo_risktype_ALA_9:
          'Högre värderade torra, gas- och flytande bulkprodukter',
        cargo_risktype_ALA_10: 'Specie, sprit & tobaksvaror, läder och hudar',
        cargo_risktype_ALA_11:
          'Ligor, mänskliga kvarstår, organ, mänskligt och djurligt blod',
        cargo_risktype_ALA_12: 'Levande djur, träd och växter',
        cargo_risktype_ALA_13: 'Övrigt/Okänd',
        temperature_controlled: 'Varorna måste vara temperaturkontrollerade',
        dangerous_goods: 'Varorna betraktas som farligt gods',
        letter_credit_text: 'Fritext Kreditbrev',
        valueofgoods: 'Godsets värde',
        transport_charges: 'Transportkostnader',
        icc_a: 'Allrisk (A)',
        icc_b: 'Utvidgad grundförsäkring (B)',
        icc_c: 'Grundskyddet (C)',
        included: 'Inkluderade',
        add_ons: 'Tilläggsklausuler',
        add_ons_incl: 'Inkluderade tilläggsklausuler',
        max_liability: 'Max. ansvar',
        civilservant_country: 'Personnummer utfärdandeland',
        civilservant_number: 'Personnummer',
        firstname: 'Förnamn',
        company_name: 'Företagsnamn',
        lastname: 'Efternamn',
        country: 'Land',
        state: 'Län',
        city: 'Stad',
        address: 'Adress',
        postal_code: 'Postnummer',
        phone: 'Telefon',
        vatnr: 'Momsnummer',
        dunsnr: 'D-U-N-S-nummer',
        companynr: 'Organisationsnummer',
        insurance_startdate: 'Startdatum för försäkring',
        insurance_enddate: 'Slutdatum för försäkring',
        cargo_description: 'Beskrivning av godset',
        marks_numbers: 'Märkning och numrering',
        carrier: 'Transportör',
        vessel_name: 'Fartygets/fordonets namn',
        voyage: 'Resenummer',
        named_place: 'Ort för tillämpad INCOTERM',
        origin_city: 'Avsändningsort (Ort där försäkringen träder i kraft)',
        loading_country:
          'Lastningsland (Landet där lastningens hamn eller flygplats ligger)',
        loading_city:
          'Lastningsort (Ort där lastningens hamn eller flygplats ligger)',
        discharge_country:
          'Lossningsland (Landet där lastningshamnen eller flygplatsen ligger)',
        discharge_city:
          'Lossningsort (Ort där lossningshamnen eller flygplatsen ligger)',
        destination_city: 'Destinationsort (Ort där försäkringsskyddet slutar)',
        reference_number: 'Referensnummer',
        nodeductibles: 'Ingen självriskrabatt tillgänglig.',
        deductible_amount: 'Självriskbelopp',
        price_after_discount: 'Pris efter rabatt',
        cant_offer: 'Kan inte erbjuda ett pris',
        details: 'Detaljer',
        insured_value: 'Försäkringsvärde',
        gross_premium: 'Bruttopremie',
        deductible_discounts: 'Självriskrabatter',
        customer_discount: 'Kundrabatter',
        other_discounts: 'Andra rabatter',
        vat: 'Moms',
        ipt: 'Försäkringsskatt',
        no_match: 'Ingen träff',
        general_terms_text:
          'Jag förstår de olika parternas roller och har läst och samtycker till att vara bunden av deras',
        terms_of_business_privacy_policy: 'Villkor & Integritetespolicy',
        insurance_terms_text: 'Jag har läst och accepterar att vara bunden av',
        insurance_clauses_conditions:
          'Försäkringsklausuler, Standard- och Särskilda villkor',
        insurance_terms_text_2: 'och är bekant med',
        insurance_terms_conditions: 'Försäkringsvillkoren',
        insurance_terms_text_3: 'och',
        data_processing_info: 'Information om databehandling',
        countries: 'länder',
        states: 'stater',
        restrictions_text:
          'Jag accepterar att försäkringen inte täcker transportsätt i följande',
        terms_of_business: 'Villkor',
        privacy_policy: 'Integritetspolicy',
        order_not_cancellable: 'Den här ordern kan inte annulleras',
        order_cancel_text: 'Den här ordern kan annulleras senast',
        order_cancel_text_2:
          'dag(ar) före påbörjandet av den försäkrade transporten',
        show_information: 'Visa information',
      },
      validation: {
        required: 'Fältet är obligatoriskt',
        email: 'Ogiltig e-postadress',
        phone_regex: 'Ogiltig inmatning',
        alpha_spaces: 'Endast bokstäver och blanksteg är giltiga',
        between: 'Endast decimaltal är giltiga',
        oddsized_items_match: 'oddsized_items_match',
        oddsized_items_error:
          'Inmatningen ska vara samma som det totala värdet av överdimensionerade artiklar',
        integer: 'Endast heltal är giltiga',
        min_value: 'Obligatoriskt',
      },
    },
  },
});

export { i18n };
